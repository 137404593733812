import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { SpinnerDotted } from "spinners-react";
import copy from "../../../assets/HomeAssets/assets/images/icon/copy.png";

const MyLinks = ({
  // active,
  // setActive,
  // setFilteredValue,
  loading,
  orderBy,
  setOrderBy,
  sortBy,
  setSortBy,
}) => {
  const selector = useSelector((store) => store.addReferrel.referralLinks);
  const [clipboardState, setClipboardState] = useState("");

  // const handleOnChange = (index, item) => {
  //   setActive(item.code);
  //   setFilteredValue(0);
  // };

  const tableHeading = [
    {
      id: 1,
      style: "br-r-8 w-153",
      text: "Title",
      value: "name",
    },
    {
      id: 2,
      style: "w-19",
      text: "Description",
      value: "description",
    },
    {
      id: 3,
      style: "w-18 pl-0",
      text: "Link",
      value: "code",
    },
    {
      id: 4,
      style: "pl-0",
      text: "Clicks",
      value: "clicks",
    },
    {
      id: 5,
      style: "pl-custom",
      text: "Accounts",
      value: "accounts",
    },
    {
      id: 6,
      style: "pl-custom",
      text: "Verified",
      value: "verified",
    },
    {
      id: 7,
      style: "pl-custom",
      text: "Unverified",
      value: "unVerified",
    },
    {
      id: 8,
      style: "pl-custom",
      text: "Profit",
      value: "profit",
    },
    {
      id: 9,
      style: "pl-custom",
      text: "Status",
      value: "isApproved",
    },
  ];
  const handleFilter = (value) => {
    setSortBy(value);
    setOrderBy(sortBy === value ? (orderBy === "asc" ? "desc" : "asc") : "asc");
  };
  return (
    <>
    <div className="container">
      <h1 className="f24 mb-0 text-white mt-64">My Links</h1>
    </div>

      {loading ? (
        <div className="showLoader">
          <SpinnerDotted style={{ height: "30", color: "white" }} />
        </div>
      ) : (
        ""
      )}

      <div className="table_width mt-32">
        <div className="custom_table    table-wrapper">
          <table className="table giro-table table-bordered">
            <thead>
              <tr>
                {tableHeading.map((item) => {
                  return (
                    <th
                      key={item.id}
                      className={item.style}
                      onClick={() => handleFilter(item.value)}
                    >
                      {item.text}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody className="scroll_bar  ">
              {selector.map((item, index) => {
                return (
                  <tr key={index}>
                    <td className="br-r-8 w-153">{item.name}</td>
                    <td className="w-19">{item.description}</td>
                    <td className="w-18">
                      {item?.isApproved ? (
                        <div className="link-box">
                          <span className="f16">
                            {clipboardState === item.code
                              ? "Link Copied!"
                              : `https://lionsdengames.net/${item.code}`}
                          </span>
                          <CopyToClipboard
                            text={`https://lionsdengames.net/${item.code}`}
                            onCopy={() => setClipboardState(item.code)}
                          >
                            <Link className="copy_wrap">
                              <img src={copy} alt="copy" />
                            </Link>
                          </CopyToClipboard>
                        </div>
                      ) : (
                        "Link will appear as soon as admin approves your request."
                      )}
                    </td>
                    <td className="text-center">{item.clicks || 0}</td>
                    <td className="pl-0 text-center">
                      {item?.accounts ? item.accounts : 0}
                    </td>
                    <td className="text-center">{item?.verified || 0}</td>
                    <td className="text-center">{item?.unVerified || 0}</td>
                    <td className="pl-0 text-center">
                      ${item?.profit ? item.profit.toFixed(4) : 0}
                    </td>
                    <td className="pl-0">
                      {item?.isApproved
                        ? "Approved"
                        : "Waiting for Admin Approval"}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default MyLinks;
