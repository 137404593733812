import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Toast from "../../../../Components/Toastify/Toastify";
import { useNavigate } from "react-router-dom";
import { SpinnerDotted } from "spinners-react";
import { addACHAccountApprovelyAPI } from "../../../../store/api/cashout";


const AddAccountACHApprovely = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  async function onSubmit() {
    setLoading(true);
    dispatch(
      addACHAccountApprovelyAPI({
        body: {},
        onSuccess: (res) => {
          setLoading(false);

          if (res?.data?.success === true) {
            if (res?.data?.url) {
              window.location.replace(res?.data?.url);
            }
          }
        },
        onError: (error) => {
          setLoading(false);
          if (error?.response?.data?.success === false) {
            Toast.error(error?.response?.data?.message);
          }
          if (
            error?.response?.data?.message ===
            "Your Session has been expired, Kindly sign in to continue."
          ) {
            navigate("/");
          }
        },
      })
    );
  }
  return (
    <>
      <button onClick={onSubmit} disabled={loading} className="cashout_btn f24 mt-8">
        {loading ? <SpinnerDotted style={{ height: "40", color: "#011343" }} /> : "Add new account"}
      </button>
    </>
  );
};

export default AddAccountACHApprovely;
