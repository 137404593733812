import { React, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import SideBar from "../../Components/Layout/SideBar/SideBar";
import DashboardHeader from "../../Components/Layout/Header/DashboardHeader";
import filter from "../../assets/HomeAssets/assets/images/filter.png";
import click from "../../assets/HomeAssets/assets/images/icon/click.png";
import account from "../../assets/HomeAssets/assets/images/icon/account.png";
import happyface from "../../assets/HomeAssets/assets/images/icon/happyface.png";
import Banknote from "../../assets/HomeAssets/assets/images/icon/Banknote.png";
import arrowdown from "../../assets/HomeAssets/assets/images/icon/arrow_down.png";
import arrowup from "../../assets/HomeAssets/assets/images/icon/arrow_up.png";
import MyLinks from "./MyLinks/MyLinks";
import { getReferral } from "../../store/api/createReferrel";
import { getAllReferrelLinks } from "../../store/ui/referrelLink";
import Toast from "../../Components/Toastify/Toastify";
import DateRangePicker from "../../Components/DateRangePicker/DateRangePicker";
import { Helmet } from "react-helmet";
// import { getUser } from "../../store/api/userEdit";

const Dashboard = () => {
  const dateRef = useRef({});
  const [showFilterList, setShowFilterList] = useState(false);
  // const [active, setActive] = useState("");
  const navigate = useNavigate();
  const { referralLinks } = useSelector((store) => store.addReferrel);
  const [orderBy, setOrderBy] = useState("asc");
  const [sortBy, setSortBy] = useState("name");
  const dispatch = useDispatch();
  // const [filteredValue, setFilteredValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleApplyDate = (e) => {
    e.preventDefault();
    // console.log("Selected start date:", startDate);
    // console.log("Selected end date:", endDate);

    setLoading(true);
    dispatch(
      getReferral({
        body: {
          // selected: active,
          // days: filteredValue ? filteredValue : 0,
          orderBy: orderBy,
          sortBy: sortBy,
          ...(startDate && {
            startDate: moment(startDate).format("YYYY-MM-DD"),
            endDate: moment(endDate).format("YYYY-MM-DD"),
          }),
        },
        onSuccess: (res) => {
          setLoading(false);
          if (res.data.success) {
            dispatch(getAllReferrelLinks(res.data));
            // if (!active) {
            //   setActive(res.data.referrals[0].code);
            // }
          } else {
            if (
              res.data.success === false &&
              (res.data.message ===
                "Your Session has been expired, Kindly sign in to continue." ||
                res.data.message ===
                  "We cant log you in. Kindly talk to customer support for further clarity.")
            ) {
              localStorage.clear();
              navigate("/");
              Toast.error(res.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
            } else {
              Toast.error(res.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
            }
          }
        },

        onError: (res) => {
          setLoading(false);
        },
      })
    );
  };

  useEffect(() => {
    dateRef.current.endDate = endDate;
    dateRef.current.startDate = startDate;
  }, [startDate, endDate]);

  useEffect(() => {
    setLoading(true);
    dispatch(
      getReferral({
        body: {
          // selected: active,
          // days: filteredValue ? filteredValue : 0,
          orderBy: orderBy,
          sortBy: sortBy,
          ...(dateRef.current.startDate &&
            dateRef.current.endDate && {
              startDate: moment(dateRef.current.startDate).format("YYYY-MM-DD"),
              endDate: moment(dateRef.current.endDate).format("YYYY-MM-DD"),
            }),
        },
        onSuccess: (res) => {
          setLoading(false);
          if (res.data.success) {
            dispatch(getAllReferrelLinks(res.data));
            // if (!active) {
            //   setActive(res.data.referrals[0].code);
            // }
          } else {
            if (
              res.data.success === false &&
              (res.data.message ===
                "Your Session has been expired, Kindly sign in to continue." ||
                res.data.message ===
                  "We cant log you in. Kindly talk to customer support for further clarity.")
            ) {
              localStorage.clear();
              navigate("/");
              Toast.error(res.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
            } else {
              Toast.error(res.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
            }
          }
        },

        onError: (res) => {
          setLoading(false);
        },
      })
    );
  }, [dispatch, navigate, orderBy, sortBy]);

  const overview = referralLinks.reduce(
    (overview, referral) => {
      const {
        clicks = 0,
        accounts = 0,
        profit = 0,
        retention = 0,
        verified = 0,
        unVerified = 0,
      } = referral;
      overview.clicks += Number(clicks) || 0;
      overview.profit += Number(profit) || 0;
      overview.accounts += Number(accounts) || 0;
      overview.verified += Number(verified) || 0;
      overview.unVerified += Number(unVerified) || 0;
      overview.retention += Number(retention) || 0;
      return overview;
    },
    {
      clicks: 0,
      accounts: 0,
      profit: 0,
      retention: 0,
      verified: 0,
      unVerified: 0,
    }
  );

  // console.log("overview referralLinks", referralLinks);
  // console.log("overview", overview);

  // const dayList = [
  //   { text: "Day", value: 1 },
  //   { text: "7 days", value: 7 },
  //   { text: "30 days", value: 30 },
  //   { text: "3 months", value: 90 },
  //   { text: "6 months", value: 180 },
  // ];

  const toggleFilterList = () => {
    setShowFilterList(!showFilterList);
  };

  return (
    <>
      <Helmet>
        <title>Lionsden Partner Portal - Dashboard</title>
        <meta
          name="description"
          content="LionsDen Games - Unleash Your Inner Lion - The Ultimate Sweepstakes Casino Experience in America. Experience the thrill, embrace the excitement, and ignite your passion at LionsDen Games – your ultimate online adventure!"
        />
      </Helmet>
      <div className="wrapper scroll-container2">
        <SideBar />
        <div id="content">
          <div className="content_inner">
            <DashboardHeader />
            <div className="dashboard-body">
              <div className="head_wrap d-flex align-items-center justify-content-between">
                <h1 className="f24 mb-0 text-white">Overview</h1>
                <div className="filter_icon">
                  <Link
                    className="NavIcon mr-0 filterbtn"
                    onClick={toggleFilterList}
                  >
                    <img src={filter} alt="filter" />
                  </Link>
                  {showFilterList && (
                    <div className="filterList">
                      {/* <ul>
                        {dayList.map((item) => {
                          return (
                            <li key={item.value}>
                              <Link
                                onClick={() => {
                                  setFilteredValue((prev) =>
                                    prev === item.value ? "" : item.value
                                  );
                                }}
                                className={
                                  filteredValue === item.value
                                    ? "selectedActiveItem"
                                    : ""
                                }
                              >
                                {item.text}
                              </Link>
                            </li>
                          );
                        })}
                      </ul> */}
                      <DateRangePicker
                        startDate={startDate}
                        endDate={endDate}
                        handleStartDateChange={handleStartDateChange}
                        handleEndDateChange={handleEndDateChange}
                        handleApplyDate={handleApplyDate}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="main_card_wrap">
                <div className="dashboard_box_detail d-flex align-items-center">
                  <span className="NavIcon bg_dark">
                    <img src={click} alt="filter" />
                  </span>
                  <div className="dashboard_info w-100">
                    <h5 className="f16 text-white">Clicks</h5>
                    <h5 className="f24 text-white d-flex w-100 align-items-center justify-content-between">
                      {overview.clicks}
                      <img src={arrowdown} className="w-16" alt="arrowdown" />
                    </h5>
                  </div>
                </div>
                <div className="dashboard_box_detail d-flex align-items-center">
                  <span className="NavIcon bg_dark">
                    <img src={account} alt="account" />
                  </span>
                  <div className="dashboard_info w-100">
                    <h5 className="f16 text-white">Accounts</h5>
                    <h5 className="f24 text-white d-flex w-100 align-items-center justify-content-between">
                      {overview.accounts}{" "}
                      <img src={arrowup} className="w-16" alt="arrowup" />
                    </h5>
                  </div>
                </div>
                <div className="dashboard_box_detail d-flex align-items-center">
                  <span className="NavIcon bg_dark">
                    <img src={happyface} alt="happyface" />
                  </span>
                  <div className="dashboard_info w-100">
                    <h5 className="f16 text-white">Approved Users</h5>
                    <h5 className="f24 text-white d-flex w-100 align-items-center justify-content-between">
                      {overview.verified}
                      <img src={arrowup} className="w-16" alt="arrowup" />
                    </h5>
                  </div>
                </div>
                <div className="dashboard_box_detail d-flex align-items-center">
                  <span className="NavIcon bg_dark">
                    <img src={account} alt="account" />
                  </span>
                  <div className="dashboard_info w-100">
                    <h5 className="f16 text-white">Unapproved Users</h5>
                    <h5 className="f24 text-white d-flex w-100 align-items-center justify-content-between">
                      {overview.unVerified}
                      <img src={arrowdown} className="w-16" alt="arrowdown" />
                    </h5>
                  </div>
                </div>
                <div className="dashboard_box_detail d-flex align-items-center">
                  <span className="NavIcon bg_dark">
                    <img src={Banknote} alt="Banknote" />
                  </span>
                  <div className="dashboard_info w-100">
                    <h5 className="f16 text-white">Profit</h5>
                    <h5 className="f24 text-white d-flex w-100 align-items-center justify-content-between">
                      ${overview.profit.toFixed(2)}{" "}
                      <img src={arrowup} className="w-16" alt="arrowup" />
                    </h5>
                  </div>
                </div>
              </div>

              <div className="main_card_wrap">
                <MyLinks
                  // active={active}
                  // setActive={setActive}
                  // setFilteredValue={setFilteredValue}
                  loading={loading}
                  setLoading={setLoading}
                  orderBy={orderBy}
                  setOrderBy={setOrderBy}
                  sortBy={sortBy}
                  setSortBy={setSortBy}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
