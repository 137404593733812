import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import SideBar from "../../../Components/Layout/SideBar/SideBar";
import DashboardHeader from "../../../Components/Layout/Header/DashboardHeader";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { baseUrl } from "../../../config";
import storage from "../../../services/storage";
import axios from "axios";
import Toast from "../../../Components/Toastify/Toastify";
import moment from "moment";
import CustomPagination from "../../../Components/Layout/CustomPagination/CustomPagination";
import AffiliateUsersTable from "../../../Components/Layout/Tables/AffiliateUsersTable";
import SearchInput from "../../../Components/Layout/CustomSearchInput/SearchInput";
import SelectOptions from "../../../Components/Layout/CustomSelectOptions/SelectOptions";

const AffilateUsers = () => {
  const dateRef = useRef({});
  const [showFilterList, setShowFilterList] = useState(false);
  // const [active, setActive] = useState("");
  const navigate = useNavigate();
  const { referralLinks } = useSelector((store) => store.addReferrel);
  // const [orderBy, setOrderBy] = useState("asc");
  // const [sortBy, setSortBy] = useState("name");
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const getFormatedDate = (date) => {
    return moment(date).format("YYYY-MM-DD");
  };
  const currentDate = new Date();
  // const currentMonth = getFormatedMonth(currentDate);
  const oneDayAgo = new Date(currentDate);
  oneDayAgo.setDate(currentDate.getDate() - 1); // Subtract 1 days
  const oneMonthAgo = new Date(currentDate);
  oneMonthAgo.setDate(currentDate.getDate() - 30); // Subtract 30 days
  const [startDate, setStartDate] = useState(oneMonthAgo);
  const [endDate, setEndDate] = useState(oneDayAgo);
  // const dispatch = useDispatch();
  const [usersData, setUsersData] = useState([]);
  // const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [sortBy, setSortBy] = useState("createdAt");
  const [orderBy, setOrderBy] = useState("desc");
  const [searchBy, setSearchBy] = useState("");
  const [perPage, setPerPage] = useState(10);
  const [totalUsers, setTotalUsers] = useState(0);

  const [searchTerm, setSearchTerm] = useState("");
  const fetchData = async () => {
    setLoading(true);
    let data = JSON.stringify({
      page: currentPage,
      perPage: perPage,
      search: searchBy,
      orderBy: orderBy,
      sortBy: sortBy,
    });

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${baseUrl}v1/partner/analytics/users?page=${currentPage}&perPage=${perPage}&search=${searchBy}&orderBy=${orderBy}&sortBy=${sortBy}`,
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": `${storage.get("xAuthToken")}`,
      },
      // data: data,
    };

    await axios
      .request(config)
      .then((res) => {
        // console.log("response of AffiliateUsers", res.data);
        setUsersData(res.data?.users || []);
        setTotalPages(res.data?.totalPages || 0);
        setTotalUsers(res.data?.totalCount || 0);
        setLoading(false);
        if (
          (res.data.success === false &&
            res.data.message ===
              "Your Session has been expired, Kindly sign in to continue.") ||
          res.data.message ===
            "Sorry! Services not available in your country." ||
          res.data.message ===
            "Sorry! Services not available in your region." ||
          res.data.message === "You cannot use LionsDen with a VPN or Proxy."
        ) {
          storage.remove("xAuthToken");
          storage.remove("user");
          // SocketService.disconnect();
          // history.push("/authentication/sign-in");
          Toast.error(res.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
      })
      .catch((error) => {});
  };

  // useEffect(() => {
  //   // dispatch(getAllUserBills({}));
  // }, [dispatch]);

  useEffect(() => {
    // console.log("fetching data");
    fetchData();
  }, [perPage, currentPage, searchBy, orderBy, sortBy]);
  const searchHandler = (value) => {
    setSearchBy(value);
    setCurrentPage(1);
  };

  const handleLoadMore = () => {
    dispatch(setLoading(true));
  };
  function formatDate(dateString) {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, options);
  }

  // new code ends here
  const [isInputVisible, setInputVisible] = useState(false);

  const handleSearchClick = () => {
    setInputVisible(!isInputVisible);
  };
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handleLimitChange = (value) => {
    setPerPage(value);
    setCurrentPage(1);
  };
  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleApplyDate = (e) => {
    e.preventDefault();
    fetchData();
    // setLoading(true);
  };

  useEffect(() => {
    dateRef.current.endDate = endDate;
    dateRef.current.startDate = startDate;
  }, [startDate, endDate]);

  const toggleFilterList = () => {
    setShowFilterList(!showFilterList);
  };
  const clearSearch = () => {
    setSearchBy("");
  };
  return (
    <>
      <Helmet>
        <title>Lionsden Partner Portal - Referral Users</title>
        <meta
          name="description"
          content="LionsDen Games - Unleash Your Inner Lion - The Ultimate Sweepstakes Casino Experience in America. Experience the thrill, embrace the excitement, and ignite your passion at LionsDen Games – your ultimate online adventure!"
        />
      </Helmet>
      <div className="wrapper scroll-container2">
        <SideBar />
        <div id="content">
          <div className="content_inner">
            <DashboardHeader />
            <div className="dashboard-body container">
              <div className="row head_wrap d-flex align-items-center justify-content-between">
                <h1 className="col-md-4 col-sm-12 f24 mb-0 text-white">Referral Users</h1>
                <div className="col-md-8 col-sm-12 d-flex justify-content-end align-items-center">
                  <span className="limit-span mr-16 col-lg-3 col-sm-6">
                    <p className="f16 mr-8 mb-0 mb-none">Per page limit</p>
                    <SelectOptions
                    selectHandler={handleLimitChange}
                    // options={options}
                    defaultValue={perPage}
                  /></span>
                  
                  <SearchInput searchHandler={searchHandler} />
                  {searchBy !== "" ? (
                    <button className="themeButtonLedger" onClick={clearSearch}>
                      Clear
                    </button>
                  ) : (
                    ""
                  )}
                </div>

                {/* <div className="filter_icon">
                  <Link
                    className="NavIcon mr-0 filterbtn"
                    onClick={toggleFilterList}
                  >
                    <img src={filter} alt="filter" />
                  </Link>
                  {showFilterList && (
                    <div className="filterList">
                      <DateRangePicker
                        startDate={startDate}
                        endDate={endDate}
                        handleStartDateChange={handleStartDateChange}
                        handleEndDateChange={handleEndDateChange}
                        handleApplyDate={handleApplyDate}
                      />
                    </div>
                  )}
                </div> */}
              </div>

              <div className="payment-wrap">
                {searchBy !== "" && (
                  <>
                    <div className="head_wrap d-flex align-items-center justify-content-between">
                      <h1 className="f24 mb-0 text-white">
                        Search Results of {searchBy}
                      </h1>
                    </div>
                  </>
                )}
                <AffiliateUsersTable
                  usersData={usersData}
                  loading={loading}
                  currentPage={currentPage}
                  perPage={perPage}
                  order={orderBy}
                  isActive={sortBy}
                  onClickColumn={(columnName) => {
                    setSortBy((prevColumnName) => {
                      if (columnName === prevColumnName)
                        setOrderBy((prev) => (prev === "desc" ? "asc" : "desc"));
                      else setOrderBy("desc");

                      return columnName;
                    });
                  }}
                />
                {usersData?.length > 0 && (
                  <CustomPagination
                    handlePageChange={handlePageChange}
                    currentPage={currentPage}
                    totalPages={totalPages}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AffilateUsers;
