import React, { useState, useEffect } from "react";
import notifaction from "../../../assets/HomeAssets/assets/images/SideBar/notifaction.png";
import gethelp from "../../../assets/HomeAssets/assets/images/SideBar/gethelp.png";
import menu from "../../../assets/HomeAssets/assets/images/menu.svg";
import close from "../../../assets/HomeAssets/assets/images/close.svg";
import closeModalImg from "../../../assets/HomeAssets/assets/images/icon/close.png";
import { Link, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { createReferral } from "../../../store/api/createReferrel";
import { useDispatch, useSelector } from "react-redux";
import Toast from "../../../Components/Toastify/Toastify";
import { addReferrelLink } from "../../../store/ui/referrelLink";
import { SpinnerDotted } from "spinners-react";
import storage from "../../../services/storage";
import { updateMenuOpen } from "../../../store/ui/userData";

const DashboardHeader = () => {
  const [showMenu, setShowMenu] = useState(false);
  const {menuOpen} = useSelector((state) => state.userData);
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => {
    dispatch(updateMenuOpen(!menuOpen));
    setShowMenu(!showMenu);
  };
  const user = storage.get("user");
  
  // useEffect(() => {
  //   if (showMenu) {
  //     document.body.classList.add("menu-open");
  //   } else {
  //     document.body.classList.remove("menu-open");
  //   }
  // }, [showMenu]);

  // createmodal openModal
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };
  const initialValues = {
    linkTitle: "",
    description: "",
  };

  const onSubmit = async (values) => {
    setLoading(true);
    dispatch(
      createReferral({
        body: {
          name: values.linkTitle.trim(),
          description: values.description.trim(),
        },
        onSuccess: (res) => {
          setLoading(false);
          if (res.data.success) {
            setIsOpen(false);
            dispatch(addReferrelLink(res.data.data));
            Toast.success(res.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
          } else {
            if (
              res.data.success == false &&
              (res.data.message ===
                "Your Session has been expired, Kindly sign in to continue." ||
                res.data.message ===
                  "We cant log you in. Kindly talk to customer support for further clarity.")
            ) {
              localStorage.clear();
              navigate("/");
              Toast.error(res.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
            } else {
              Toast.error(res.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
            }
          }
        },
        onError: (error) => {
          setLoading(false);
          if (error.response) {
            if (error.response.data.errors) {
              for (let key in error.response.data.errors) {
                setError(error.response.data.errors[key]);
                Toast.error(error.response.data.errors[key], {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "dark",
                });
              }
            } else if (error.response.data.message) {
              setError(error.response.data.message);
              Toast.error(error.response.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
            }
          }
        },
      })
    );
  };

  const validationSchema = Yup.object({
    linkTitle: Yup.string()
      .required("Title is Required")
      .trim()
      .min(2, "Title must be at least 2 characters long")
      .max(80, "Title must be at most 80 characters long"),
    description: Yup.string()
      .required("Description is Required")
      .trim()
      .min(3, "Description must be at least 3 characters long")
      .max(180, "Messages must be at most 180 characters long"),
  });

  return (
    <>
      <div className="DashboardHeader d-flex align-items-center justify-content-between">
        <div className="btn_create">
            <button
            className="themebtn-blue w-216 themeButton"
            onClick={openModal}
          >
            Create link
          </button>
        </div>
        <div className="right_icon">
          <ul className="d-flex">
            <li>
              {/* <Link className="NavIcon">
                <img src={gethelp} alt="gethelp" />
              </Link> */}
            </li>
            <li>
              {/* <Link className="NavIcon">
                <img src={notifaction} alt="notifaction" />
              </Link> */}
            </li>
            <li className="menu_icon" onClick={toggleMenu}>
              <Link className="NavIcon">
                {showMenu ? (
                  <img src={close} alt="close" />
                ) : (
                  <img src={menu} alt="menu" />
                )}
              </Link>
            </li>
          </ul>
        </div>
      </div>

      <div className="creat-modal ">
        {isOpen && (
          <div className="modal-overlay">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({
                values,
                handleChange,
                handleBlur,
                handleSubmit,
                errors,
                touched,
              }) => (
                <>
                  <div className="modal-content input-box">
                    <button onClick={closeModal} className="close-icon">
                      <img src={closeModalImg} />
                    </button>
                    <h2 className="f24 text-white text-left ">Create Link</h2>
                    <div className="input-box-field form-floating mt-32">
                      <input
                        type="text"
                        className="form-control login-form-control"
                        id="LinkTitle"
                        placeholder="First name"
                        autoComplete="off"
                        onChange={handleChange("linkTitle")}
                        onBlur={handleBlur("linkTitle")}
                        value={values.linkTitle}
                      />
                      <label htmlFor="LinkTitle">Link Title</label>
                      {errors.linkTitle && touched.linkTitle ? (
                        <div style={{ color: "red" }}>
                          <small>{errors.linkTitle}</small>
                        </div>
                      ) : null}
                    </div>
                    <div className="input-box-field form-floating ">
                      <input
                        type="text"
                        className="form-control login-form-control"
                        id="Description"
                        placeholder="Username"
                        autoComplete="off"
                        onChange={handleChange("description")}
                        onBlur={handleBlur("description")}
                        value={values.description}
                      />
                      <label htmlFor="Description">Description</label>
                      {errors.description && touched.description ? (
                        <div style={{ color: "red" }}>
                          <small>{errors.description}</small>
                        </div>
                      ) : null}
                    </div>

                    <div className="form-btn d-flex mt-64">
                      <button
                        type="button"
                        className="themebtn-dark w-100 h-56 mr-16"
                        onClick={closeModal}
                      >
                        {" "}
                        Cancel{" "}
                      </button>
                      <button
                        type="button"
                        className="themebtn-blue w-100 text_blue  h-56 "
                        onClick={handleSubmit}
                        disabled={loading ? true : false}
                      >
                        {loading ? (
                          <SpinnerDotted
                            style={{ height: "40", color: "#011343" }}
                          />
                        ) : (
                          "Create link"
                        )}
                      </button>
                    </div>
                  </div>
                </>
              )}
            </Formik>
          </div>
        )}
      </div>
    </>
  );
};

export default DashboardHeader;
