import http from "../../services/http";
import { handleErrors } from "../../helpers/error";
import storage from "../../services/storage";
import { Toast } from "bootstrap";
import {
  addUsersCard,
  // addVirtualCard,
  // deleteVirtualCard,
  getCoinRate,
  getUsersACHApprovely,
  getUsersCard,
  getUsersCardAch,
  getUsersCardApprovely,
  setThreeDFingerprintingData,
  setThreeDSecureUrl,
  // setVirtualCard,
  setWhiteListedStates,
} from "../ui/cashout.slice";
import { setUserCashoutHistory } from "../ui/cashoutHistory.slice";
const coin = "v1/cart/convertionrate";

// export const cashout = ({ body = {}, onSuccess, onError, onEnd }) => {
//   return async (dispatch) => {
//     try {
//       const res = await http.post({
//         url: "v1/checkbook/account/vcc",
//         headers: { "x-auth-token": storage.get("xAuthToken") },
//         body,
//       });
//       onSuccess?.(res);
//       if (res?.data?.success === true) {
//          dispatch(addVirtualCard(res?.data?.data));
//       }
//     } catch (error) {
//       if (error?.response?.data?.error?.message === "jwt expired") {
//         Toast.error("User Session expire");
//         storage.remove("xAuthToken");
//         storage.remove("user");
//         window.location.reload();
//       }
//       if (onError) onError(error);
//       handleErrors(error);
//     } finally {
//       if (onEnd) onEnd();
//     }
//   };
// };
export const cashoutwithdraw = ({ body = {}, onSuccess, onError, onEnd }) => {
  return async (dispatch) => {
    try {
      const res = await http.post({
        // url: "v1/checkbook/check/digital/vcc",
        url: "v1/nuvei/payout",
        headers: { "x-auth-token": storage.get("xAuthToken") },
        body,
      });
      onSuccess?.(res);
    } catch (error) {
      if (onError) onError(error);
      handleErrors(error);
    } finally {
      if (onEnd) onEnd();
    }
  };
};
// export const deleteCard = ({ id, onSuccess, onError, onEnd }) => {
//   return async (dispatch) => {
//     try {
//       const res = await http.delete({
//         url: `v1/checkbook/account/vcc/${id}`,
//         headers: { "x-auth-token": storage.get("xAuthToken") },
//       });
//       onSuccess?.(res);
//       if (res?.data?.success === true) {
//         dispatch(deleteVirtualCard(id));
//       }
//     } catch (error) {
//       if (onError) onError(error);
//       handleErrors(error);
//     } finally {
//       if (onEnd) onEnd();
//     }
//   };
// };

// export const getCashoutDetails = ({
//   headers = {},
//   onSuccess,
//   onError,
//   onEnd,
// }) => {
//   return async (dispatch) => {
//     try {
//       const res = await http.get({
//         url: "v1/checkbook/account/vcc",
//         headers: { "x-auth-token": storage.get("xAuthToken") },
//       });
//       onSuccess?.(res);
//       // dispatch(setVirtualCard(res?.data?.data?.vccs));
//     } catch (error) {
//       if (error?.response?.data?.message === "Invalid token.") {
//         Toast.error("User Session expire");
//         storage.remove("xAuthToken");
//         storage.remove("user");
//         window.location.reload();
//       }
//     } finally {
//       if (onEnd) onEnd();
//     }
//   };
// };
export const getConversionRate = ({ onSuccess, onError, onEnd }) => {
  return async (dispatch) => {
    try {
      const res = await http.get({
        url: coin,
      });
      onSuccess(res);
      if (res.data.success === true) {
        dispatch(getCoinRate(res.data.data));
      }
    } catch (error) {
      if (onError) onError(error);
      handleErrors(error);
    } finally {
      if (onEnd) onEnd();
    }
  };
};

// cashout white listed
export const getWhiteListedStates = ({ onSuccess, onError, onEnd }) => {
  return async (dispatch) => {
    try {
      const res = await http.get({
        url: "v1/admin/whitelist_states/whitelist",
        headers: {
          "x-auth-token": storage.get("xAuthToken"),
        },
      });
      onSuccess?.(res);
      dispatch(setWhiteListedStates(res?.data?.states));
    } catch (error) {
      if (onError) onError(error);
      handleErrors(error);
    } finally {
      if (onEnd) onEnd();
    }
  };
};
// cashout with nuevi
export const getUsersCardApi = ({ onSuccess, onError, onEnd }) => {
  return async (dispatch) => {
    try {
      const res = await http.get({
        url: "v1/nuvei/cards/partners",
        headers: {
          "x-auth-token": storage.get("xAuthToken"),
        },
      });
      onSuccess?.(res);
      dispatch(getUsersCard(res?.data?.cards));
      dispatch(getUsersCardAch(res?.data?.paymentMethods));
    } catch (error) {
      if (onError) onError(error);
      handleErrors(error);
    } finally {
      if (onEnd) onEnd();
    }
  };
};
export const getUsersCardApprovelyApi = ({ onSuccess, onError, onEnd }) => {
  return async (dispatch) => {
    try {
      const res = await http.get({
        url: "v1/payment/coinflow/withdrawer",
        headers: {
          "x-auth-token": storage.get("xAuthToken"),
        },
      });

      onSuccess?.(res);
      // console.log("res of get card approvely", res.data.withdrawer);
      dispatch(getUsersCardApprovely(res.data.withdrawer.cards || []));
      dispatch(getUsersACHApprovely(res.data.withdrawer.bankAccounts || []));
    } catch (error) {
      if (onError) onError(error);
      // handleErrors(error);
      // console.log("res of get card approvely error", error);
    } finally {
      if (onEnd) onEnd();
    }
  };
};
export const registrationApprovely = ({ body, onSuccess, onError, onEnd }) => {
  return async (dispatch) => {
    try {
      const res = await http.post({
        url: "v1/payment/coinflow/kyc/partner",
        body,
        headers: {
          "x-auth-token": storage.get("xAuthToken"),
        },
      });
      onSuccess?.(res);
    } catch (error) {
      // ("in error=====>", error.response.message);
      if (onError) onError(error);
      handleErrors(error);
    } finally {
      if (onEnd) onEnd();
    }
  };
};
export const firstPaymentUrl = ({ body = {}, onSuccess, onError, onEnd }) => {
  return async (dispatch) => {
    try {
      const res = await http.post({
        url: "v1/nuvei/payment/first",
        body,
        headers: {
          "x-auth-token": storage.get("xAuthToken"),
        },
      });
      onSuccess?.(res);
    } catch (error) {
      if (onError) onError(error);
      handleErrors(error);
    } finally {
      if (onEnd) onEnd();
    }
  };
};
export const paymentApi = ({ body = {}, onSuccess, onError, onEnd }) => {
  return async (dispatch) => {
    try {
      const res = await http.post({
        url: "v1/nuvei/init",
        body,
        headers: {
          "x-auth-token": storage.get("xAuthToken"),
        },
      });
      if (res?.data?.card) {
        dispatch(addUsersCard(res?.data?.card));
        onSuccess?.(res);
      } else {
        dispatch(setThreeDFingerprintingData(res?.data));
        dispatch(
          firstPaymentUrl({
            body: {
              ...body,
              version: res?.data?.paymentOption?.card?.threeD?.version,
              relatedTransactionId: res?.data?.transactionId,
            },
            onSuccess: (res1) => {
              if (res1?.data?.redirect === true) {
                dispatch(setThreeDSecureUrl(res1?.data));
              } else if (res1?.data?.card) {
                dispatch(addUsersCard(res1?.data?.card));
              }

              onSuccess?.(res1);
            },
            onError: (error) => {
              onError?.(error);
            },
          })
        );
      }
      // console.log("resp arry ", res.data.data[0].data);
    } catch (error) {
      // console.log("in error=====>", error.response.message);
      if (onError) onError(error);
      handleErrors(error);
    } finally {
      if (onEnd) onEnd();
    }
  };
};
export const deleteUsersSavedCards = ({
  item,
  index,
  onSuccess,
  onError,
  onEnd,
  id,
}) => {
  return async (dispatch) => {
    try {
      const res = await http.delete({
        url: `v1/nuvei/cards/${id}/partners`,
        headers: { "x-auth-token": storage.get("xAuthToken") },
      });
      onSuccess(res);
    } catch (error) {
      if (onError) onError(error);
      handleErrors(error);
    } finally {
      if (onEnd) onEnd();
    }
  };
};
// cashout

// add bank or card for ach
export const AchAddBank = ({ body = {}, onSuccess, onError, onEnd }) => {
  return async (dispatch) => {
    try {
      const res = await http.post({
        url: "v1/nuvei/account-capture/partners",
        body,
        headers: {
          "x-auth-token": storage.get("xAuthToken"),
        },
      });
      onSuccess?.(res);
    } catch (error) {
      if (onError) onError(error);
      handleErrors(error);
    } finally {
      if (onEnd) onEnd();
    }
  };
};

// delete ach user card
export const deleteUsersSavedCardsAch = ({
  item,
  index,
  onSuccess,
  onError,
  onEnd,
  id,
}) => {
  return async (dispatch) => {
    try {
      const res = await http.delete({
        url: "v1/nuvei/banks/" + id,
        headers: { "x-auth-token": storage.get("xAuthToken") },
      });
      onSuccess(res);
    } catch (error) {
      if (onError) onError(error);
      handleErrors(error);
    } finally {
      if (onEnd) onEnd();
    }
  };
};

export const getUserCashoutHistory = ({
  perPage,
  perPageLimit,
  onSuccess,
  onError,
  onEnd,
}) => {
  return async (dispatch) => {
    try {
      const res = await http.get({
        url: `v1/partner/payouts/history?page=${perPage}&perPage=${perPageLimit}`,
        headers: { "x-auth-token": storage.get("xAuthToken") },
      });
      onSuccess?.(res);
      if (res?.data?.success === true) {
        dispatch(setUserCashoutHistory(res?.data));
      }
    } catch (error) {
      if (onError) onError(error);
      handleErrors(error);
    } finally {
      if (onEnd) onEnd();
    }
  };
};

export const addCardNuveiCashierAPI = ({
  body = {},
  onSuccess,
  onError,
  onEnd,
}) => {
  return async (dispatch) => {
    try {
      const res = await http.post({
        url: "v1/nuvei/cashier/card-capture/partners",
        body,
        headers: {
          "x-auth-token": storage.get("xAuthToken"),
        },
      });
      onSuccess?.(res);
      // console.log("resp arry ", res.data.data[0].data);
    } catch (error) {
      // console.log("in error=====>", error.response.message);
      if (onError) onError(error);
      handleErrors(error);
    } finally {
      if (onEnd) onEnd();
    }
  };
};
export const kycCheckForApprovely = ({ onSuccess, onError, onEnd }) => {
  return async (dispatch) => {
    try {
      const res = await http.get({
        url: "v1/payment/coinflow/kyc",
        headers: { "x-auth-token": storage.get("xAuthToken") },
      });
      onSuccess?.(res);
      if (res.data.success === true) {
        // console.log('res of kycCheckForApprovely', res.data)
        // dispatch(getUsersCardApprovely(res?.data?.kycStatus?.cards));
        // dispatch(getUsersACHApprovely(res?.data?.kycStatus?.bankAccounts));
      }
    } catch (error) {
      if (onError) onError(error);
      handleErrors(error);
    } finally {
      if (onEnd) onEnd();
    }
  };
};
export const addACHAccountApprovelyAPI = ({ onSuccess, onError, onEnd }) => {
  return async (dispatch) => {
    try {
      const res = await http.get({
        url: "v1/payment/coinflow/bank-capture",
        headers: {
          "x-auth-token": storage.get("xAuthToken"),
        },
      });
      onSuccess?.(res);
    } catch (error) {
      if (onError) onError(error);
      handleErrors(error);
    } finally {
      if (onEnd) onEnd();
    }
  };
};

export const addCardApprovelyCoinFlowAPI = ({ body, onSuccess, onError, onEnd }) => {
  return async (dispatch) => {
    try {
      const res = await http.post({
        url: "v1/payment/coinflow/cards",
        body,
        headers: {
          "x-auth-token": storage.get("xAuthToken"),
        },
      });
      onSuccess?.(res);
    } catch (error) {
      // ("in error=====>", error.response.message);
      if (onError) onError(error);
      handleErrors(error);
    } finally {
      if (onEnd) onEnd();
    }
  };
};
export const deleteCardsApprovely = ({ item, index, onSuccess, onError, onEnd, id }) => {
  return async (dispatch) => {
    try {
      const res = await http.delete({
        url: "v1/payment/coinflow/delete-card/" + id,
        headers: { "x-auth-token": storage.get("xAuthToken") },
      });
      onSuccess(res);
    } catch (error) {
      if (onError) onError(error);
      handleErrors(error);
    } finally {
      if (onEnd) onEnd();
    }
  };
};
export const deleteAccountAchApprovely = ({ item, index, onSuccess, onError, onEnd, id }) => {
  return async (dispatch) => {
    try {
      const res = await http.delete({
        url: "v1/payment/coinflow/delete-bank/" + id,
        headers: { "x-auth-token": storage.get("xAuthToken") },
      });
      onSuccess(res);
    } catch (error) {
      if (onError) onError(error);
      handleErrors(error);
    } finally {
      if (onEnd) onEnd();
    }
  };
};

export const cashoutwithdrawApprovely = ({ body = {}, onSuccess, onError, onEnd }) => {
  return async (dispatch) => {
    try {
      const res = await http.post({
        // url: "v1/checkbook/check/digital/vcc",
        url: "v1/payment/coinflow/cashout/partner",
        headers: { "x-auth-token": storage.get("xAuthToken") },
        body,
      });
      onSuccess?.(res);
    } catch (error) {
      if (onError) onError(error);
      handleErrors(error);
    } finally {
      if (onEnd) onEnd();
    }
  };
};