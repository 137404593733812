import { createSlice } from "@reduxjs/toolkit";
import storage from "../../services/storage";

const user = storage.get("user") || {};

const initialState = {
  user,
  allowedPaymentMethods: {
    cards: [],
    IBFT: [],
  },
};

const slice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      const user = action.payload || {};
      state.user = user;
      storage.store("user", user);
    },
    updateUser: (state, action) => {
      state.user = action.payload;
    },
    resetUser: (state) => {
      state = initialState;
    },
    setAllowedPaymentMethods: (state, action) => {
      // console.log("action.payload of allowedPaymentMethods", action.payload);
      const cards = action.payload.cards || [] ;
      const IBFT = action.payload.IBFT || [];
      // const allowedPaymentMethodsObject = {cards: cards, IBFT: IBFT};
      state.allowedPaymentMethods.cards = cards;
      state.allowedPaymentMethods.IBFT = IBFT;
      // console.log('allowedPaymentMethodsObject', typeof(allowedPaymentMethodsObject))
      // localStorage.setItem("allowed", { cards: action.payload.cards, IBFT: action.payload.IBFT });
    },
  },
});

export const { setUser, updateUser, resetUser, setAllowedPaymentMethods } =
  slice.actions;
export default slice.reducer;
