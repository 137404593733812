import React, { useRef, useState } from "react";
import { CoinflowCardNumberInput, CoinflowCvvInput } from "@coinflowlabs/react";
// import InputMask from "react-input-mask";
import { SpinnerDotted } from "spinners-react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addCardApprovelyCoinFlowAPI, getUsersCardApprovelyApi } from "../../../../store/api/cashout";
import Toast from "../../../../Components/Toastify/Toastify";
import { env } from "../../../../config";

function AddCardApprovely({ hanldeShowAddCardApprovely }) {
  const coinflowCardFormRef = useRef(null);
  const [cardFormExp, setCardFormExp] = useState("");
  const [expiryMonth, setExpiryMonth] = useState("");
  const [expiryYear, setExpiryYear] = useState("");
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const currentYear = new Date().getFullYear();
  const currentMonth = String(new Date().getMonth() + 1).padStart(2, "0");
  const maxYear = currentYear + 10;
  const maxMonth = "12";
  const handleDateChange = (e) => {
    setCardFormExp(e.target.value);
    const selectedDate = e.target.value;
    const [year, month] = selectedDate.split("-");
    const shortYear = year.slice(-2);
    const currentDate = new Date();
    const selectedExpiryDate = new Date(year, month - 1);
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    if (selectedExpiryDate < currentDate) {
      setError("The selected date is expired.");
    } else if (year > maxYear) {
      setError("The selected date is too far in the future.");
    } else {
      setError("");
      setExpiryMonth(month);
      setExpiryYear(shortYear);
    }
  };

  const handleSubmit = () => {
    if (error) {
      alert("Please select a valid expiration date.");
      return;
    }

    alert(`Expiry Date: ${expiryMonth}/${expiryYear}`);
  };
  const handleSavedCard = () => {
    coinflowCardFormRef.current
      ?.getToken()
      .then((tokenData) => {
        setLoading(true);
        dispatch(
          addCardApprovelyCoinFlowAPI({
            body: {
              cardToken: tokenData?.token,
              expMonth: expiryMonth,
              expYear: expiryYear,
            },
            onSuccess: (res) => {
              setLoading(false);
              if (res?.data?.success === true) {
                Toast.success(res?.data?.message);
                dispatch(
                  getUsersCardApprovelyApi({
                    onSuccess: (res) => {
                      if (res?.data?.success === true) {
                        // console.log("res2", res);
                      }
                    },
                  })
                );
                hanldeShowAddCardApprovely(false);
              }
            },
            onError: (error) => {
              setLoading(false);
              if (error?.response?.data?.success === false) {
                Toast.error(error?.response?.data?.message);
              }
              if (
                error?.response?.data?.message ===
                "Your Session has been expired, Kindly sign in to continue."
              ) {
                navigate("/");
              }
            },
          })
        );
      })
      .catch((err) => console.error("GET TOKEN ERROR", { err }));
  };

  return (
    <div>
      <CoinflowCardNumberInput
        ref={coinflowCardFormRef}
        env={env} // Change to 'prod' for production
        debug={false} // Change to false for production
        css={{
          base: "font-family: Montserrat, sans-serif; padding: 0 8px;  margin: 0; width: 100%; font-size: 13px; line-height: 48px; height: 48px; box-sizing: border-box; -moz-box-sizing: border-box; border: 2px solid #484a4e; border-radius: 12px; background-color: #000; color: #fff;",
          focus: "outline: 0;",
          error:
            "box-shadow: 0 0 6px 0 rgba(224, 57, 57, 0.5); border: 1px solid rgba(224, 57, 57, 0.5);",
          cvv: {
            base: "font-family: Montserrat, sans-serif; padding: 0 8px;  margin: 0; width: 100%; font-size: 13px; line-height: 48px; height: 48px; box-sizing: border-box; -moz-box-sizing: border-box; border: 2px solid #484a4e; border-radius: 12px; background-color: #000; color: #fff;",
            focus: "outline: 0;",
            error:
              "box-shadow: 0 0 6px 0 rgba(224, 57, 57, 0.5); border: 1px solid rgba(224, 57, 57, 0.5);",
          },
        }}
      />
      {/* </div> */}
      <div className="input_wrap form-floating">
        <CoinflowCvvInput />
      </div>

      <div className="input_wrap form-floating">
        <input
          type="month"
          className="form-control"
          id="floatingInput"
          placeholder="Expiration"
          name="phone_number"
          min={`${currentYear}-${currentMonth}`}
          max={`${maxYear}-${maxMonth}`}
          value={cardFormExp}
          onChange={(e) => handleDateChange(e)}
        />
        <label htmlFor="floatingInput">Expiration</label>
      </div>
      {error && <p style={{ color: "red" }}>{error}</p>}
      <div className="support_btn mt-32 d-flex  justify-content-between">
        <button
          onClick={hanldeShowAddCardApprovely}
          className={`f24 themebtn-dark box-red cashout-btn `}
        >
          Cancel
        </button>
        <button
          id="getToken"
          onClick={() => {
            handleSavedCard();
          }}
          disabled={error !== "" || loading ? true : false}
          className={`f24 themebtn-blue box-red cashout-btn `}
        >
          {loading ? <SpinnerDotted style={{ height: "40", color: "white" }} /> : "Save Card"}
        </button>
      </div>
    </div>
  );
}
export default AddCardApprovely;
