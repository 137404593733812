import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { SpinnerDotted } from "spinners-react";
// import { theme } from "../../../config";
import Toast from "../../../../Components/Toastify/Toastify";
import { useDispatch } from "react-redux";
import { registrationApprovely } from "../../../../store/api/cashout";
import { getFormatedDate } from "../../../../services/number.services";

export default function FormKycApprovely({ setStatusKycMethod, setStatusKyc }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("");
  const formik = useFormik({
    initialValues: {
      email: "",
      firstName: "",
      surName: "",
      physicalAddress: "",
      city: "",
      state: "",
      zip: "",
      dob: "",
      ssn: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .max(50, "First name must be at most 50 characters")
        .required("First name is required"),
      surName: Yup.string()
        .max(100, "Surname must be at most 100 characters")
        .required("Surname is required"),
      physicalAddress: Yup.string()
        .max(255, "Physical address must be at most 255 characters")
        .required("Physical address is required"),
      city: Yup.string()
        .max(50, "City must be at most 50 characters")
        .required("City is required"),
      state: Yup.string()
        .max(50, "State must be at most 50 characters")
        .required("State is required"),
      zip: Yup.string()
        .min(5, "Zip code must be at least 5 characters")
        .max(10, "Zip code must be at most 10 characters")
        .required("Zip code is required"),
      dob: Yup.string()
        // .matches(/^\d{4}\/\d{2}\/\d{2}$/, "DOB must be in YYYY/MM/DD format")
        .required("Date of birth is required"),
      ssn: Yup.string()
        .matches(/^\d{4}$/, "SSN must be exactly 4 digits")
        .required("SSN is required"),
    }),
    onSubmit: (values) => {
      // console.log(values);
      setLoading(true);
// let data = {
//   email: values.email,
//             firstName: values.firstName,
//             surName: values.surName,
//             physicalAddress: values.physicalAddress,
//             city: values.city,
//             state: values.state,
//             zip: values.zip,
//             dob: getFormatedDate(values.dob),
//             ssn: values.ssn,
// }

// console.log('data of KYCApprovely submitted', data)
      dispatch(
        registrationApprovely({
          body: {
            email: values.email,
            firstName: values.firstName,
            surName: values.surName,
            physicalAddress: values.physicalAddress,
            city: values.city,
            state: values.state,
            zip: values.zip,
            dob: getFormatedDate(values.dob),
            ssn: values.ssn,
          },
          onSuccess: (res) => {
            // console.log('res.data of KYCApprovely', res.data);
            if(res?.data.redirect
            ){
              window.location.replace(res?.data.redirectURL);
            }
            if (
              res?.data?.data?.withdrawer?.verification?.status === "approved"
            ) {
              setStatusKycMethod(false);
              setStatusKyc(false);
            } else {
              setStatus(res.data.message);
            }
            setLoading(false);
          },
          onError: (error) => {
            setLoading(false);
            Toast.error(error?.response?.data?.message);
          },
        })
      );
    },
  });

  return (
    <>
      {status ? (
        <h2 className="f20 mt-32 ">{status}</h2>
      ) : (
        <div className="main-wrap-form">
          <form onSubmit={formik.handleSubmit}>
            <h2 className="f20 mt-32 mb-16 ">Extra Security Check Required</h2>
            {/* Email Field */}
            <div className="input_wrap form-floating mb-3">
              <input
                type="email"
                className={`form-control ${
                  formik.touched.email && formik.errors.email
                    ? "is-invalid"
                    : ""
                }`}
                id="email"
                name="email"
                placeholder="Enter your email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <label htmlFor="email">Email</label>
              {formik.touched.email && formik.errors.email && (
                <div className="invalid-feedback">{formik.errors.email}</div>
              )}
            </div>

            <div className="expire_wrapper_cashout">
              <div className="w49">
                {/* First Name */}
                <div className="input_wrap form-floating mb-3">
                  <input
                    type="text"
                    className={`form-control ${
                      formik.touched.firstName && formik.errors.firstName
                        ? "is-invalid"
                        : ""
                    }`}
                    id="firstName"
                    name="firstName"
                    placeholder="Enter your first name"
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <label htmlFor="firstName">First Name</label>
                  {formik.touched.firstName && formik.errors.firstName && (
                    <div className="invalid-feedback">
                      {formik.errors.firstName}
                    </div>
                  )}
                </div>
              </div>
              <div className="w49">
                {/* Surname */}
                <div className="input_wrap form-floating mb-3">
                  <input
                    type="text"
                    className={`form-control ${
                      formik.touched.surName && formik.errors.surName
                        ? "is-invalid"
                        : ""
                    }`}
                    id="surName"
                    name="surName"
                    placeholder="Enter your surname"
                    value={formik.values.surName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <label htmlFor="surName">Surname</label>
                  {formik.touched.surName && formik.errors.surName && (
                    <div className="invalid-feedback">
                      {formik.errors.surName}
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/* Physical Address */}
            <div className="input_wrap form-floating mb-3">
              <input
                type="text"
                className={`form-control ${
                  formik.touched.physicalAddress &&
                  formik.errors.physicalAddress
                    ? "is-invalid"
                    : ""
                }`}
                id="physicalAddress"
                name="physicalAddress"
                placeholder="Enter your physical address"
                value={formik.values.physicalAddress}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <label htmlFor="physicalAddress">Physical Address</label>
              {formik.touched.physicalAddress &&
                formik.errors.physicalAddress && (
                  <div className="invalid-feedback">
                    {formik.errors.physicalAddress}
                  </div>
                )}
            </div>

            {/* City */}

            {/* State */}

            <div className="expire_wrapper_cashout">
              <div className="w49">
                {" "}
                <div className="input_wrap form-floating mb-3">
                  <input
                    type="text"
                    className={`form-control ${
                      formik.touched.city && formik.errors.city
                        ? "is-invalid"
                        : ""
                    }`}
                    id="city"
                    name="city"
                    placeholder="Enter your city"
                    value={formik.values.city}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <label htmlFor="city">City</label>
                  {formik.touched.city && formik.errors.city && (
                    <div className="invalid-feedback">{formik.errors.city}</div>
                  )}
                </div>
              </div>
              <div className="w49">
                <div className="input_wrap form-floating mb-3">
                  <input
                    type="text"
                    className={`form-control ${
                      formik.touched.state && formik.errors.state
                        ? "is-invalid"
                        : ""
                    }`}
                    id="state"
                    name="state"
                    placeholder="Enter your state"
                    value={formik.values.state}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <label htmlFor="state">State</label>
                  {formik.touched.state && formik.errors.state && (
                    <div className="invalid-feedback">
                      {formik.errors.state}
                    </div>
                  )}
                </div>
              </div>

              {/* Zip Code */}
            </div>
            <div className="expire_wrapper_cashout">
              <div className="w49">
                <div className="input_wrap form-floating mb-3">
                  <input
                    type="text"
                    className={`form-control ${
                      formik.touched.zip && formik.errors.zip
                        ? "is-invalid"
                        : ""
                    }`}
                    id="zip"
                    name="zip"
                    placeholder="Enter your zip code"
                    value={formik.values.zip}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <label htmlFor="zip">Zip Code</label>
                  {formik.touched.zip && formik.errors.zip && (
                    <div className="invalid-feedback">{formik.errors.zip}</div>
                  )}
                </div>
              </div>
              <div className="w49">
                <div className="input_wrap form-floating mb-3">
                  <input
                    type="date"
                    className={`form-control ${
                      formik.touched.dob && formik.errors.dob
                        ? "is-invalid"
                        : ""
                    }`}
                    id="dob"
                    name="dob"
                    placeholder="YYYY/MM/DD"
                    value={formik.values.dob}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <label htmlFor="dob">Date of Birth</label>
                  {formik.touched.dob && formik.errors.dob && (
                    <div className="invalid-feedback">{formik.errors.dob}</div>
                  )}
                </div>
              </div>
            </div>
            {/* Date of Birth */}

            <div className="input_wrap form-floating">
              <input
                type="text"
                className={`form-control ${
                  formik.touched.ssn && formik.errors.ssn ? "is-invalid" : ""
                }`}
                id="floatingInput"
                name="ssn"
                placeholder="Enter your SSN"
                value={formik.values.ssn}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <label htmlFor="floatingInput">Four digits SSN</label>
              {formik.touched.ssn && formik.errors.ssn ? (
                <div className="invalid-feedback">{formik.errors.ssn}</div>
              ) : null}
            </div>

            <button
              disabled={loading ? true : false}
              className={`f24 themebtn-blue box-red cashout-btn `}
              type="submit"
            >
              {loading ? (
                <SpinnerDotted style={{ height: "40", color: "white" }} />
              ) : (
                "Submit"
              )}
            </button>
          </form>
        </div>
      )}
    </>
  );
}
