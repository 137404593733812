import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import Toast from "../../../Components/Toastify/Toastify";

// images for cashouts
import DeleteImg from "../../../assets/HomeAssets/assets/images/cashout/del-icon.png";
import GoldCoin from "../../../assets/HomeAssets/assets/images/cashout/lionspidecoin.png";
import Setting from "../../../assets/HomeAssets/assets/images/cashout/setting.png";
import mastercard from "../../../assets/HomeAssets/assets/images/cashout/mastercard.png";
import visacard from "../../../assets/HomeAssets/assets/images/cashout/visa-card.png";
import americanexpress from "../../../assets/HomeAssets/assets/images/cashout/amex.png";
import discover from "../../../assets/HomeAssets/assets/images/cashout/discover.png";
import defaultCard from "../../../assets/HomeAssets/assets/images/cashout/default.png";
import arrowDown from "../../../assets/HomeAssets/assets/images/cashout/downArrow.png";

import { number, expirationDate, cvv } from "card-validator";
import InputMask from "react-input-mask";
import * as Yup from "yup";
import "yup-phone";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { SpinnerDotted } from "spinners-react";

// All API's
import {
  AchAddBank,
  // cashout,
  cashoutwithdraw,
  cashoutwithdrawApprovely,
  deleteAccountAchApprovely,
  deleteCardsApprovely,
  // deleteCard,
  deleteUsersSavedCards,
  deleteUsersSavedCardsAch,
  // getCashoutDetails,
  getConversionRate,
  getUsersCardApi,
  getUsersCardApprovelyApi,
  getWhiteListedStates,
  paymentApi,
} from "../../../store/api/cashout";
import { getUser } from "../../../store/api/userEdit";
import { useNavigate } from "react-router-dom";
import {
  deleteCardApprovely,
  deleteUserCard,
  deleteUserCardAch,
} from "../../../store/ui/cashout.slice";
import { toast } from "react-toastify";

import { Link } from "react-router-dom";
import SideBar from "../../../Components/Layout/SideBar/SideBar";
import DashboardHeader from "../../../Components/Layout/Header/DashboardHeader";
import Banknote from "../../../assets/HomeAssets/assets/images/icon/Banknote.png";
import setting from "../../../assets/HomeAssets/assets/images/icon/setting.png";
import PayoutHistory from "./PayoutHistory/PayoutHistory";
import "../../../assets/HomeAssets/assets/css/cashout.css";
import { Helmet } from "react-helmet";
import Select from "react-select";
import AddCardCashier from "./AddCardCashier";
import AddCardApprovely from "./AddCardApprovely/AddCardApprovely";
import FormKycApprovely from "./FormKycApprovely/FormKycApprovely";
import AddAccountACHApprovely from "./AddAccountApprovely/AddAccountACHApprovely";
// validation schemas
const validationSchema = Yup.object({
  name: Yup.string().required("Required"),
  street: Yup.string().required("Required"),
  city: Yup.string().required("Required"),
  state: Yup.string().min(2).max(2).required("Required"),
  phone: Yup.string()
    .min(10)
    .test("phone-validation", "Please enter a valid number", function (value) {
      const phoneNumber = parsePhoneNumberFromString(value);
      return phoneNumber && phoneNumber.isValid();
    })
    .required("Phone number is required"),
  zip: Yup.string().required("Required"),
});
const validationSchemaForm2 = Yup.object({
  amount: Yup.number()
    .min(15, "Min daily withdrawal: $15. Enter a higher amount.")
    .required("Withdrawal amount is required"),
});
const initialValuesAch = {
  // accNumber: "",
  // accType: "",
  // routing_num: "",
  firstname: "",
  lastname: "",
  billAddress1: "",
  city: "",
  state: "",
  zipCode: "",
  country: "United States",
  phone_number: "",
};
const validationSchemaAch = Yup.object({
  billAddress1: Yup.string()
    .required("Address line 1 is required")
    .max(30, "Maximum length is 30"),
  city: Yup.string()
    .required("City is required")
    .max(30, "Maximum length is 30"),
  state: Yup.string()
    .min(1)
    .max(3, "Maximum length is 3")
    .required("State is required"),
  zipCode: Yup.string()
    .required("Zip code is required")
    .max(10, "Maximum length is 10"),
  country: Yup.string().required("Country is required"),
  phone_number: Yup.string()
    .test("phone-validation", "Please enter a valid number", function (value) {
      const phoneNumber = parsePhoneNumberFromString(`${value}`, "US");
      return phoneNumber && phoneNumber.isValid();
    })
    .matches(/^\+?[0-9]+$/, "Phone number should contain only digits")
    .required("Phone number is required"),
  // routing_num: Yup.string("Routing number must be digits")
  //   .matches(/^[0-9]+$/, "Routing number should contain only digits")
  //   .min(9, "Minimum length is 9")
  //   .max(9, "Maximum length is 9")
  //   .required("Routing number is required"),
  // accNumber: Yup.string()
  //   .required("Account Number is required")
  //   .matches(/^[0-9]+$/, "Routing number should contain only digits")
  //   .min(14, "Account Number must be 14 digits")
  //   .max(16, "Account Number must not exceed 16 digits"),
  // accType: Yup.string().required("Account type is required"),
});

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    boxShadow: state.isFocused ? "none" : provided.boxShadow,
    borderColor: state.isFocused ? "none" : provided.borderColor,
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "white", // Color of the selected value
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "white", // Set placeholder text color to white
  }),
  // option: (provided, state) => ({
  //   ...provided,
  //   color: state.isSelected ? "black" : "black",
  //   zIndex: 99999922222, // Color of options when menu is open
  // }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "white" : "white",
    zIndex: 99999922222, // Color of options when menu is open
    backgroundColor: state.isSelected
      ? "radial-gradient(77.34% 77.34% at 50% 22.66%,#6ed3ff 0%,#2c0e82 100%)"
      : "",
    "&:hover": {
      background:
        "radial-gradient(77.34% 77.34% at 50% 22.66%,#6ed3ff 0%,#2c0e82 100%)",
      boxShadow: "0px 6px 10px 1px rgba(0, 0, 0, 0.32)",
    },
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 10000000000, // Set a high z-index for the dropdown menu
    backgroundColor: "black",
  }),
};

const Billing = ({ backToMainCashoutScreen }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loadingAch, setLoadingAch] = useState(false);
  const [loadingWithdraw, setLoadingWithdraw] = useState(false);
  const [cardLoading, setCardLoading] = useState({});
  const [cardLoadingAch, setCardLoadingAch] = useState({});
  const [showAddCard, setClsoeAddCard] = useState(false);
  const [showAddCardAch, setClsoeAddCardAch] = useState(false);
  const [statusKyc, setStatusKyc] = useState(false);
  const [statusKycMethod, setStatusKycMethod] = useState(false);
  const [success, setSuccess] = useState(false);
  const [fail, setFail] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  // const [selectedCardAch, setSelectedCardAch] = useState(null);
  const [cashOutFoam, setCashOutFoam] = useState(false);
  const [cardTypeCheck, setCardTypeCheck] = useState("");
  const {
    // virtualCards = [],
    userSavedCardsACH = [],
    userApprovelySavedCards = [],
    userApprovelySavedACH = [],
    whiteListedStates = [],
    userSavedCards = [],
    threeDSecureUrl = {},
    threeDFingerprinting = {},
    coin = "",
  } = useSelector((state) => state.userCashout) || {};
  // console.log("userApprovelySavedCards", userApprovelySavedCards);
  // console.log('userApprovelySavedACH', userApprovelySavedACH);
  const { acsUrl, cReq } = threeDSecureUrl;
  const { methodUrl, methodPayload } =
    threeDFingerprinting?.paymentOption?.card?.threeD || {};
  const { coins = {} } = useSelector((state) => state?.editUser?.user) || {};
  let duaWalCoins = coins.purchasedWallet + coins.winWallet;
  let winningsCoins = coins.winWallet;
  const isCoinsHas =
    duaWalCoins !== null && !isNaN(duaWalCoins) ? duaWalCoins : 0;
  const isWingsCoins =
    duaWalCoins !== null && !isNaN(winningsCoins) ? winningsCoins : 0;
  const states = useSelector((state) => state?.editUser?.state) || {};
  const user = useSelector((state) => state?.editUser?.user) || {};
  const allowedPaymentMethods =
    useSelector((state) => state?.user.allowedPaymentMethods) || {};
  const { totalPaidOut } = useSelector((state) => state.cashoutHistory) || 0;
  console.log("allowedPaymentMethods in cashout", allowedPaymentMethods);
  useEffect(() => {
    setCashOutFoam(false);
  }, [backToMainCashoutScreen]);
  useEffect(() => {
    dispatch(getUser({}));
  }, [dispatch]);
  useEffect(() => {
    dispatch(
      getConversionRate({
        onSuccess: (res) => {},
      })
    );
  }, [dispatch]);
  // useEffect(() => {
  //   dispatch(getCashoutDetails({}));
  // }, [dispatch]);
  useEffect(() => {
    dispatch(getUsersCardApi({}));
    dispatch(
      getUsersCardApprovelyApi({
        onSuccess: (res) => {
          // console.log("jhkjhsakjfhkasjdfhkasjhfdkajshfkajshdfwithdrawer", res.data);

          if (res?.data?.verified === true) {
            // console.log("res of withdrawer", res.data);
            // setStatusKyc(true);
            setStatusKycMethod(false);
          } else {
            setStatusKycMethod(true);
          }
        },
      })
    );
  }, [dispatch]);
  useEffect(() => {
    if (methodUrl && methodPayload) {
      document.frm.submit();
    }
  }, [methodUrl, methodPayload]);
  useEffect(() => {
    dispatch(getWhiteListedStates({}));
  }, []);
  useEffect(() => {
    if (acsUrl && cReq) {
      document.frm1.submit();
    }
  }, [acsUrl, cReq]);
  function ExpirationDateformat(expirationDate = "") {
    if (!expirationDate || typeof expirationDate !== "string") {
      return "--/--";
    }
    const [year, month] = expirationDate.split("-");
    return ` ${month}/${year.slice(-2)}`;
  }
  // filter ach cards
  const userAddedAchCards = userSavedCardsACH?.filter((item) => {
    return item.paymentMethodName === "apmgw_eCheckSelect";
  });
  // filter
  const checkIs = whiteListedStates?.some((item, index) => {
    return item.region === states?.region;
  });
  const findstate = whiteListedStates?.find((item, index) => {
    if (item.region === states.region) return states.region;
  });

  const myState = states?.country;

  const [handleSwitchCashoutMethod, setHandleSwitchCashoutMethod] = useState(
    "ach"
  );
  const [cardCashoutMethod, setCardCashoutMethod] = useState("nuvei");
  const [achCashoutMethod, setAchCashoutMethod] = useState("nuvei");
  const [showAddCardApprovely, setShowAddCardApprovely] = useState(false);
  // console.log("statusKycMethod", statusKycMethod);
  // console.log("statusKyc", statusKyc);

  useEffect(() => {
    if (allowedPaymentMethods.cards.includes("nuvei")) {
      setCardCashoutMethod("nuvei");
    } else {
      setCardCashoutMethod("approvely");
    }
if(allowedPaymentMethods.IBFT.length > 0){
  setHandleSwitchCashoutMethod("ach");
}else{
  setHandleSwitchCashoutMethod("credit/debit");
}
    if (allowedPaymentMethods.IBFT.includes("nuvei")) {
      setAchCashoutMethod("nuvei");
    } else {
      setAchCashoutMethod("approvely");
    }
  }, [allowedPaymentMethods]);
  const NumberTokG = (num) => {
    if (num < 10000) {
      let cNum = Number(num);
      return cNum.toFixed(2);
    }
    return `${new Intl.NumberFormat("en-US", {
      maximumFractionDigits: 2,
      notation: "compact",
      compactDisplay: "short",
    }).format(num)}`;
  };
  const hanldeShowAddCard = () => {
    setClsoeAddCard(true);
    setSelectedCard(null);
  };
  const hanldeShowAddCardAch = () => {
    setClsoeAddCardAch(true);
    setSelectedCard(null);
  };
  const hanldeShowAddCardClose = () => {
    resetForm();
    setClsoeAddCard(false);
  };
  const hanldeShowAddCardCloseAch = () => {
    resetForm3({});
    setClsoeAddCardAch(false);
  };
  const hanldeShowAddCardApprovely = () => {
    setShowAddCardApprovely(!showAddCardApprovely);
    setSelectedCard(null);
  };
  const handleDeleteCardsApprovely = (e, id) => {
    e.stopPropagation();
    setCardLoading((prevLoading) => ({ ...prevLoading, [id]: true }));
    dispatch(
      deleteCardsApprovely({
        id,
        onSuccess: (res) => {
          setCardLoading((prevLoading) => ({ ...prevLoading, [id]: true }));
          if (res?.data?.success === true) {
            dispatch(deleteCardApprovely(id));
            Toast.success("Card Deleted Successfully");
            if (id === selectedCard) {
              setSelectedCard(null);
            }
          }
          if (res?.data?.success === false) {
            Toast.error(res?.data?.message);
          }
        },
        onError: (error) => {
          setCardLoading((prevLoading) => ({ ...prevLoading, [id]: true }));
          Toast.error(error?.response?.data?.message);
        },
      })
    );
  };
  const handleDeleteAccountACHApprovely = (e, id) => {
    e.stopPropagation();
    setCardLoading((prevLoading) => ({ ...prevLoading, [id]: true }));
    dispatch(
      deleteAccountAchApprovely({
        id,
        onSuccess: (res) => {
          setCardLoading((prevLoading) => ({ ...prevLoading, [id]: true }));
          if (res?.data?.success === true) {
            dispatch(deleteCardApprovely(id));
            Toast.success("Account Deleted Successfully");
            if (id === selectedCard) {
              setSelectedCard(null);
            }
          }
          if (res?.data?.success === false) {
            Toast.error(res?.data?.message);
          }
        },
        onError: (error) => {
          setCardLoading((prevLoading) => ({ ...prevLoading, [id]: true }));
          Toast.error(error?.response?.data?.message);
        },
      })
    );
  };
  const onSubmitForm2 = async (values) => {
    let data = {
      ...values,
      userPaymentOptionId: selectedCard.toString(),
    };
    setLoadingWithdraw(true);
    if (
      (handleSwitchCashoutMethod === "ach" && achCashoutMethod === "nuvei") ||
      (handleSwitchCashoutMethod === "credit/debit" &&
        cardCashoutMethod === "nuvei")
    ) {
      dispatch(
        cashoutwithdraw({
          body: data,
          onSuccess: (res) => {
            setLoadingWithdraw(false);
            dispatch(getUser({}));
            setSuccess(true);
            if (res?.data?.success === true) {
              toast.success("Payout submitted! Await processing");
            }
            setTimeout(() => {
              setSuccess(false);
              setCashOutFoam(false);
            }, 3000);
          },
          onError: (error) => {
            setLoadingWithdraw(false);
            if (error?.response?.data?.success === false) {
              Toast.error(error?.response?.data?.message);
              if (
                error?.response?.data?.message ===
                "Your Session has been expired, Kindly sign in to continue."
              ) {
                navigate("/");
              }
            }
            if (error?.response?.data?.success === false) {
              setFail(true);
              setTimeout(() => {
                setFail(false);
              }, 3000);
            }
          },
        })
      );
    } else if (
      (handleSwitchCashoutMethod === "credit/debit" &&
        cardCashoutMethod === "approvely") ||
      (handleSwitchCashoutMethod === "ach" && achCashoutMethod === "approvely")
    ) {
      dispatch(
        cashoutwithdrawApprovely({
          body: data,
          onSuccess: (res) => {
            setLoadingWithdraw(false);
            dispatch(getUser({}));
            setSuccess(true);
            if (res?.data?.success === true) {
              toast.success("Cash out submitted! Await processing");
            }
            setTimeout(() => {
              setSuccess(false);
              setCashOutFoam(false);
            }, 3000);
          },
          onError: (error) => {
            setLoadingWithdraw(false);
            if (error?.response?.data?.success === false) {
              Toast.error(error?.response?.data?.message);
              if (
                error?.response?.data?.message ===
                "Your Session has been expired, Kindly sign in to continue."
              ) {
                navigate("/");
              }
            }
            if (error?.response?.data?.success === false) {
              setFail(true);
              setTimeout(() => {
                setFail(false);
              }, 3000);
            }
          },
        })
      );
    } else {
    }
  };
  const handleUnsetCard = () => {
    setSelectedCard(null);
  };
  const handleDeleteCards = (e, id) => {
    e.stopPropagation();
    setCardLoading((prevLoading) => ({ ...prevLoading, [id]: true }));
    dispatch(
      deleteUsersSavedCards({
        id,
        onSuccess: (res) => {
          setCardLoading((prevLoading) => ({ ...prevLoading, [id]: true }));
          if (res?.data?.success === true) {
            dispatch(deleteUserCard(id));
            Toast.success("Card Deleted Successfully");
            if (id === selectedCard) {
              setSelectedCard(null);
            }
          }
          if (res?.data?.success === false) {
            Toast.error(res?.data?.message);
          }
        },
        onError: (error) => {
          setCardLoading((prevLoading) => ({ ...prevLoading, [id]: true }));
          Toast.error(error?.response?.data?.message);
        },
      })
    );
  };
  const handleDeleteCardsAch = (e, id) => {
    e.stopPropagation();
    setCardLoadingAch((prevLoading) => ({ ...prevLoading, [id]: true }));
    dispatch(
      deleteUsersSavedCardsAch({
        id,
        onSuccess: (res) => {
          setCardLoadingAch((prevLoading) => ({ ...prevLoading, [id]: true }));
          if (res?.data?.success === true) {
            dispatch(deleteUserCardAch(id));
            Toast.success("Account Deleted Successfully");
            if (id === selectedCard) {
              setSelectedCard(null);
              resetForm3();
            }
          }
          if (res?.data?.success === false) {
            Toast.error(res?.data?.message);
          }
        },
        onError: (error) => {
          setCardLoadingAch((prevLoading) => ({ ...prevLoading, [id]: true }));
          Toast.error(error?.response?.data?.message);
        },
      })
    );
  };
  const handleSelectedCard = (id) => {
    if (selectedCard === id) {
      setSelectedCard("");
    } else {
      setSelectedCard(id);
    }
  };
  const handleCashout = () => {
    if (selectedCard) {
      setCashOutFoam(true);
    } else {
      Toast.info("Please Select the Card for Cash-out");
    }
  };
  const handleSettingBtn = () => {
    if (loadingWithdraw) return;
    setCashOutFoam(false);
  };
  const cardImages = {
    VISA: visacard,
    visa: visacard,
    MASTERCARD: mastercard,
    mastercard: mastercard,
    AMEX: americanexpress,
    americanexpress: americanexpress,
    discover: discover,
    DISCOVER: discover,
  };
  const formikProps2 = useFormik({
    initialValues: {
      amount: "",
    },
    validationSchema: validationSchemaForm2,
    onSubmit: onSubmitForm2,
  });
  const onSubmitFormAch = async (values) => {
    setLoadingAch(true);
    dispatch(
      AchAddBank({
        body: {
          // accNumber: values.accNumber,
          // accType: values.accType,
          // routing: values.routing_num,
          // amount: 101,
          userDetails: {
            firstName: values.firstname,
            lastName: values.lastname,
            address: values.billAddress1,
            phone: values.phone_number,
            city: values.city,
            zip: values.zipCode.toString(),
            state: values.state,
            country: "US",
          },
        },
        onSuccess: (res) => {
          setLoadingAch(false);
          if (res?.data?.redirectUrl) {
            window.location.replace(res?.data?.redirectUrl);
          }
          // if (res?.data?.success === true) {
          //   toast.success(res?.data?.message);
          //   resetForm3({});
          //   setClsoeAddCardAch(false);
          //   dispatch(getUsersCardApi({}));
          // }
        },
        onError: (error) => {
          setLoadingAch(false);
          if (error?.response?.data?.success === false) {
            Toast.error(error?.response?.data?.message);
            if (
              error?.response?.data?.message ===
              "Your Session has been expired, Kindly sign in to continue."
            ) {
              navigate("/");
            }
          }
        },
      })
    );
  };
  const {
    values: values2,
    handleChange: handleChange2,
    handleBlur: handleBlur2,
    handleSubmit: handleSubmit2,
    errors: errors2,
    touched: touched2,
    setFieldValue: setFieldValue2,
    setFieldError: setFieldError2,
    resetForm,
  } = formikProps2;
  const formikPropsAch = useFormik({
    initialValues: initialValuesAch,
    validationSchema: validationSchemaAch,
    onSubmit: onSubmitFormAch,
  });
  const {
    values: valuesAch,
    handleChange: handleChangeAch,
    handleBlur: handleBlurAch,
    handleSubmit: handleSubmitAch,
    errors: errorsAch,
    touched: touchedAch,
    setFieldValue: setFieldValueAch,
    setFieldError: setFieldErrorAch,
    resetForm: resetForm3,
  } = formikPropsAch;
  const initialValues = {
    cardNumber: "",
    expiry: "",
    cvv: "",
    name: "",
    billAddress1: "",
    billAddress2: "",
    city: "",
    state: "",
    zipCode: "",
    country: "United States",
    savecard: true,
  };
  const validationSchema = Yup.object({
    cardNumber: Yup.string().test(
      "test-card",
      "Credit card number is invalid",
      (value) => {
        const cardValidationResult = number(value);
        const isValidCardNumber = cardValidationResult.isValid;
        const cardType = cardValidationResult.card?.type || ""; // Get the card type
        setCardTypeCheck(cardType.replace("-", ""));
        return isValidCardNumber;
      }
    ),
    expiry: Yup.string()
      .test(
        "test-expiry",
        "Invalid expiry date",
        (value) => expirationDate(value).isValid
      )
      .required("Expiry date is required"),
    cvv: Yup.string()
      // .test("test-cvv", "Invalid CVV", (value) => cvv(value).isValid)
      .min(3, "Security is Invalid")
      .required("CVV is required"),
    name: Yup.string()
      .required("Name is required")
      .min(6, "Name must have at least 6 characters")
      .max(20, "Name must not exceed 20 characters"),
    billAddress1: Yup.string().required("Address 1 is required"),
    billAddress2: Yup.string().required("Address 2 is required"),
    city: Yup.string().required("City is required"),
    state: Yup.string().min(2).max(5).required("State is required"),
    zipCode: Yup.string().required("Zip code is required"),
    country: Yup.string().required("Country is required"),
  });
  const formikProps = useFormik({ initialValues, validationSchema, onSubmit });
  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    errors,
    touched,
    setFieldValue,
    setFieldError,
  } = formikProps;
  const handleCardNumberChange = (e) => {
    const value = e.target.value.replace(/\s/g, "");
    setFieldValue("cardNumber", value);
  };
  const handleExpiryChange = (e) => {
    const value = e.target.value;
    setFieldValue("expiry", value);
  };
  const handleCvvChange = (e) => {
    const value = e.target.value;
    setFieldValue("cvv", value);
  };
  const getCardMask = () => {
    const cardNumberValue = values.cardNumber || "";
    const cardNumberValidator = number(cardNumberValue);
    const gaps = cardNumberValidator.card?.gaps || [];
    const lengths = cardNumberValidator.card?.lengths || [16];
    const maxLength = Math.max(...lengths);
    let mask = "";
    let gapIndex = 0;
    for (let i = 0; i < maxLength; i++) {
      if (i === 16) {
        mask += " "; // Add space after the 16th digit
      }

      if (gapIndex < gaps.length && i === gaps[gapIndex]) {
        mask += " ";
        gapIndex++;
      }
      mask += "9"; // Add "9" as a placeholder for digits
    }
    if (!cardNumberValidator.card?.type) {
      mask = "9999 9999 9999 9999 999";
    }

    return mask;
  };
  async function onSubmit(values) {
    setLoading(true);
    dispatch(
      paymentApi({
        body: {
          cardHolderName: values.name,
          cardNumber: values.cardNumber,
          expiry: new Date(`01/${values.expiry}`).toLocaleDateString(),
          CVV: values.cvv,
          address: {
            line_1: values.billAddress1,
            line_2: values.billAddress2,
            city: values.city,
            country: values.country,
            zip: values.zipCode.toString(),
            state: values.state,
          },
          savecard: values.savecard,
        },
        onSuccess: (res) => {
          setLoading(false);
          if (res?.data?.redirect) return;
          if (res?.data?.success === true) {
            Toast.success(res?.data?.message);
            formikProps.resetForm({});
            resetForm();
            setClsoeAddCard(false);
          }
        },
        onError: (error) => {
          setLoading(false);
          if (error?.response?.data?.success === false) {
            Toast.error(error?.response?.data?.message);
          }
          if (
            error?.response?.data?.message ===
            "Your Session has been expired, Kindly sign in to continue."
          ) {
            navigate("/");
          }
        },
      })
    );
  }
  const handleSwitchCashout = (method) => {
    if (method === "ach") {
      setHandleSwitchCashoutMethod("ach");
      setSelectedCard(null);
      if (statusKycMethod) {
        setStatusKyc(true);

        setAchCashoutMethod("approvely");
      }
    } else {
      setHandleSwitchCashoutMethod("credit/debit");
      setSelectedCard(null);
      if (statusKycMethod) {
        setStatusKyc(true);
        setCardCashoutMethod("approvely");
      }
    }
  };
  const handleSwitchCardPaymentMethod = (method) => {
    setCardCashoutMethod(method);
    setSelectedCard(null);
    setShowAddCardApprovely(false);
    if (method === "nuvei") {
      setStatusKyc(false);
    } else if (statusKycMethod) {
      setStatusKyc(true);
    }
  };
  const handleSwitchACHPaymentMethod = (method) => {
    setAchCashoutMethod(method);
    setSelectedCard(null);
    setShowAddCardApprovely(false);
    setClsoeAddCardAch(false);
    if (method === "nuvei") {
      setStatusKyc(false);
    } else if (statusKycMethod) {
      setStatusKyc(true);
    }
  };
  const handleDropDown = () => {
    setOpenDropdown(!openDropdown);
  };
  const handleOptionValue = (value) => {
    setFieldValueAch("accType", value);
    setOpenDropdown(false);
  };
  const handleCheckDropDownIsOpen = () => {
    if (openDropdown === true) {
      setOpenDropdown(false);
    }
  };
  return (
    <>
      <Helmet>
        <title>Lionsden Partner Portal - Billing</title>
        <meta
          name="description"
          content="LionsDen Games - Unleash Your Inner Lion - The Ultimate Sweepstakes Casino Experience in America. Experience the thrill, embrace the excitement, and ignite your passion at LionsDen Games – your ultimate online adventure!"
        />
      </Helmet>
      <div className="wrapper scroll-container2">
        <SideBar />
        <div id="content">
          <div className="content_inner">
            <DashboardHeader />
            <div className="dashboard-body">
              <h1 className="f48 mb-0 text-white">Billing </h1>

              <div className="main_card_wrap mt-32">
                <div className="dashboard_box_detail Billing_card w-352 d-flex align-items-center">
                  <span className="NavIcon bg_dark">
                    <img src={Banknote} alt="Banknote" />
                  </span>
                  <div className="dashboard_info w-100">
                    <h5 className="f16  text-white d-flex w-100 align-items-center justify-content-between">
                      Current Balance
                      <Link to="/settings">
                        <img src={setting} className=" " alt="arrowdown" />
                      </Link>
                    </h5>
                    <h5 className="f36 text-white current-text mt-2">
                      {" "}
                      $
                      {user?.partnerPayment
                        ? NumberTokG(user?.partnerPayment)
                        : 0}
                    </h5>
                    {/* <p className="f16a mt-2 mb-0">
                      Upcoming Payout Date: July 15, 2023
                    </p> */}
                  </div>
                </div>
                <div className="dashboard_box_detail Billing_card w-352 d-flex align-items-center">
                  <span className="NavIcon bg_dark">
                    <img src={Banknote} alt="Banknote" />
                  </span>
                  <div className="dashboard_info w-100">
                    <h5 className="f16  txt-lit d-flex w-100 align-items-center justify-content-between">
                      Total Paid Out{" "}
                    </h5>
                    <h5 className="f36 txt-lit  mt-2">
                      <b> ${NumberTokG(totalPaidOut)} </b>
                    </h5>
                    {/* <p className="f16 mt-2 mb-0 txt-lit">As of June 30, 2023</p> */}
                  </div>
                </div>
              </div>
              <div className="payment-wrap">
                <PayoutHistory />
              </div>
            </div>
          </div>
          {/* Comment out the UI for cashout of partner */}
          {(allowedPaymentMethods.cards.length > 0 || allowedPaymentMethods.IBFT.length > 0) && user.payoutsAllowed && (
            <div className="wrapper">
              {methodUrl && methodPayload && (
                <>
                  <iframe
                    title="hiddenFrame"
                    name="hiddenFrame"
                    width="0"
                    height="0"
                    border="0"
                    style={{ display: "none" }}
                  />

                  <form
                    name="frm"
                    method="POST"
                    target="hiddenFrame"
                    action={methodUrl}
                  >
                    <input
                      type="hidden"
                      name="threeDSMethodData"
                      value={methodPayload}
                    />
                  </form>
                </>
              )}
              {acsUrl && cReq && (
                <>
                  <form name="frm1" method="POST" action={acsUrl}>
                    creq:
                    <input type="area" id="creq" name="creq" value={cReq} />
                    ;
                    <input type="submit" value="proceed to issuer" />
                  </form>
                </>
              )}
              <div
                onClick={handleCheckDropDownIsOpen}
                className="theme-container Editprofile   z-9"
              >
                {!cashOutFoam ? (
                  <>
                    <div className="fomBox add_cashout_btnWrap bt-padd-10 mb-0">
                      <div className="swich_cashout_btn">
                        {allowedPaymentMethods.cards.length > 0 && (
                           <button
                           onClick={() => handleSwitchCashout("credit/debit")}
                           className={`${
                             handleSwitchCashoutMethod === "credit/debit"
                               ? "active"
                               : ""
                           }`}
                         >
                           Credit/Debit
                         </button>
                        )}
                        {allowedPaymentMethods.IBFT.length > 0 && (
                          <button
                          onClick={() => handleSwitchCashout("ach")}
                          className={`${
                            handleSwitchCashoutMethod === "ach" ? "active" : ""
                          }`}
                        >
                          Instant Bank Transfer
                        </button>
                        )}
                        
                      </div>
                      {handleSwitchCashoutMethod === "credit/debit" ? (
                        <>
                          <div className="swich_cashout_btn">
                            {allowedPaymentMethods.cards.includes(
                              "approvely"
                            ) && (
                              <button
                                onClick={() =>
                                  handleSwitchCardPaymentMethod("approvely")
                                }
                                className={`${
                                  cardCashoutMethod === "approvely"
                                    ? "active"
                                    : ""
                                }`}
                              >
                                Approvely
                              </button>
                            )}
                            {allowedPaymentMethods.cards.includes("nuvei") && (
                              <button
                                onClick={() =>
                                  handleSwitchCardPaymentMethod("nuvei")
                                }
                                className={`${
                                  cardCashoutMethod === "nuvei" ? "active" : ""
                                }`}
                              >
                                Nuvei
                              </button>
                            )}
                          </div>
                          <>
                            <div className="credit-debit-wrap">
                              {statusKyc ? null : (
                                <>
                                  <h2 className="f20 ">
                                    {!userSavedCards?.length > 0
                                      ? "Add a card to cash out"
                                      : "Manage your linked cards"}
                                  </h2>
                                  <p className="f14">
                                    {!userSavedCards?.length > 0 ? (
                                      <>
                                        To proceed with your withdrawals, please
                                        link a valid card and provide additional
                                        required details, including your phone
                                        number. Click "Add New Card" to begin.
                                      </>
                                    ) : (
                                      <>
                                        You've already added one or more cards
                                        for cashing out. You can add additional
                                        cards as needed. Remember to keep your
                                        details, including your phone number, up
                                        to date.
                                      </>
                                    )}
                                  </p>
                                  <h2 className="f20 mt-32 ">
                                    {!userSavedCards?.length > 0
                                      ? "New Card"
                                      : "Saved Cards"}{" "}
                                  </h2>
                                </>
                              )}
                              {!showAddCard && (
                                <>
                                  {cardCashoutMethod === "nuvei" &&
                                    userSavedCards?.map((item, key) => (
                                      <div
                                        key={key}
                                        onClick={() =>
                                          handleSelectedCard(
                                            item?.userPaymentOptionId
                                          )
                                        }
                                        className={`user_cards_wrap ${
                                          item?.userPaymentOptionId ===
                                          selectedCard
                                            ? "active"
                                            : ""
                                        }`}
                                      >
                                        <div className="user_card ">
                                          <div className="card_img d-flex">
                                            <img
                                              src={
                                                cardImages[
                                                  item?.card?.cardBrand ||
                                                    item?.card?.cardCompany
                                                ] || defaultCard
                                              }
                                              alt="card-img"
                                            />
                                          </div>
                                          <div className="card_details">
                                            <h2 className="f20 ">
                                              {item?.card?.cardCompany} ¨¨{" "}
                                              {item?.card?.last4Digits}
                                            </h2>
                                            <p className="f14">
                                              Expires on{" "}
                                              {`${item?.card?.ccExpMonth}/${item?.card?.ccExpYear}`}
                                            </p>
                                          </div>
                                          <div className="delete_card d-flex justify-content-center">
                                            {cardLoading[
                                              item?.userPaymentOptionId
                                            ] ? (
                                              <>
                                                <SpinnerDotted
                                                  style={{
                                                    height: "24",
                                                    color: "white",
                                                  }}
                                                />
                                              </>
                                            ) : (
                                              <>
                                                <img
                                                  onClick={(e) =>
                                                    handleDeleteCards(
                                                      e,
                                                      item?.userPaymentOptionId
                                                    )
                                                  }
                                                  src={DeleteImg}
                                                  alt="del"
                                                />
                                              </>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  {statusKyc ? null : (
                                    <>
                                      {allowedPaymentMethods.cards.includes(
                                        "approvely"
                                      ) &&
                                        cardCashoutMethod === "approvely" &&
                                        !showAddCardApprovely &&
                                        userApprovelySavedCards?.map(
                                          (item, key) => (
                                            <div
                                              key={key}
                                              onClick={() =>
                                                handleSelectedCard(item?.token)
                                              }
                                              className={`user_cards_wrap  ${
                                                item?.token === selectedCard
                                                  ? "active"
                                                  : ""
                                              }`}
                                            >
                                              <div className="user_card ">
                                                <div className="card_img d-flex">
                                                  <img
                                                    src={
                                                      cardImages[item?.type] ||
                                                      defaultCard
                                                    }
                                                    alt="card-img"
                                                  />
                                                </div>
                                                <div className="card_details">
                                                  <h2 className="f20 ">
                                                    {item?.type} ¨¨{" "}
                                                    {item?.last4}
                                                  </h2>
                                                  <p className="f14">
                                                    Added on{" "}
                                                    {ExpirationDateformat(
                                                      item?.createdAt
                                                    )}
                                                  </p>
                                                </div>
                                                <div className="delete_card d-flex justify-content-center">
                                                  {cardLoading[item?.token] ? (
                                                    <>
                                                      <SpinnerDotted
                                                        style={{
                                                          height: "24",
                                                          color: "white",
                                                        }}
                                                      />
                                                    </>
                                                  ) : (
                                                    <>
                                                      <img
                                                        onClick={(e) =>
                                                          handleDeleteCardsApprovely(
                                                            e,
                                                            item?.token
                                                          )
                                                        }
                                                        src={DeleteImg}
                                                        alt="del"
                                                      />
                                                    </>
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          )
                                        )}
                                    </>
                                  )}
                                </>
                              )}
                              {statusKyc ? (
                                <FormKycApprovely
                                  setStatusKycMethod={setStatusKycMethod}
                                  setStatusKyc={setStatusKyc}
                                />
                              ) : null}
                              {statusKyc ? null : (
                                <>
                                  {!showAddCard && (
                                    <div className="cashout_btn_wrap ">
                                      {allowedPaymentMethods.cards.includes(
                                        "approvely"
                                      ) &&
                                        !statusKycMethod &&
                                        !showAddCardApprovely &&
                                        cardCashoutMethod === "approvely" && (
                                          <button
                                            type="button"
                                            onClick={hanldeShowAddCardApprovely}
                                            className="cashout_btn f24 mt-8"
                                          >
                                            Add new card
                                          </button>
                                        )}
                                      {allowedPaymentMethods.cards.includes(
                                        "nuvei"
                                      ) &&
                                        cardCashoutMethod === "nuvei" && (
                                          <AddCardCashier />
                                        )}
                                      {allowedPaymentMethods.cards.includes(
                                        "approvely"
                                      ) &&
                                      cardCashoutMethod === "approvely" &&
                                      statusKycMethod ? (
                                        <FormKycApprovely
                                          setStatusKycMethod={
                                            setStatusKycMethod
                                          }
                                          setStatusKyc={setStatusKyc}
                                        />
                                      ) : (
                                        allowedPaymentMethods.cards.includes(
                                          "approvely"
                                        ) &&
                                        showAddCardApprovely && (
                                          <AddCardApprovely
                                            hanldeShowAddCardApprovely={
                                              hanldeShowAddCardApprovely
                                            }
                                          />
                                        )
                                      )}
                                    </div>
                                  )}
                                </>
                              )}

                              {/* {
                                  showAddCard && (
                                    <>
                                      <form
                                        className="fomBox "
                                        onClick={handleUnsetCard}
                                        onSubmit={handleSubmit}
                                      >
                                        <div className="main-wrap-form">
                                          <div className="input_wrap form-floating">
                                            <input
                                              type="text"
                                              className="form-control"
                                              id="floatingInput"
                                              placeholder="Enter your name"
                                              onBlur={handleBlur("name")}
                                              value={values.name}
                                              onChange={handleChange("name")}
                                            />
                                            <label htmlFor="floatingInput">
                                              Name on card
                                            </label>
                                          </div>
                                          {errors.name && touched.name ? (
                                            <div style={{ color: "red" }}>
                                              <small>{errors.name}</small>
                                            </div>
                                          ) : null}
                                          <div className="input_wrap form-floating">
                                            <InputMask
                                              mask={getCardMask()}
                                              maskChar=""
                                              name="cardNumber"
                                              value={values.cardNumber}
                                              onChange={handleCardNumberChange}
                                              onBlur={handleBlur}
                                              className="form-control"
                                              placeholder="4524 8698 7521 5889"
                                            />

                                            <img
                                              className="img_icon img_icon_card_cashout "
                                              alt={
                                                cardImages[values.cardType] ||
                                                ""
                                              }
                                              src={
                                                cardImages[cardTypeCheck] ||
                                                defaultCard
                                              }
                                            />

                                            <label htmlFor="floatingInput">
                                              Card number
                                            </label>
                                          </div>
                                          {errors.cardNumber &&
                                          touched.cardNumber ? (
                                            <div style={{ color: "red" }}>
                                              <small>{errors.cardNumber}</small>
                                            </div>
                                          ) : null}
                                          <div className="expire_wrapper_cashout">
                                            <div className="expire_wrap_inner">
                                              <div className="input_wrap form-floating">
                                                <InputMask
                                                  name="expiry"
                                                  className="form-control"
                                                  placeholder="MM/YY"
                                                  mask="99/99"
                                                  maskChar=""
                                                  value={values.expiry}
                                                  onChange={handleExpiryChange}
                                                  onBlur={handleBlur}
                                                />
                                                <label htmlFor="floatingInput">
                                                  Expiry
                                                </label>
                                              </div>
                                              {errors.expiry &&
                                              touched.expiry ? (
                                                <div style={{ color: "red" }}>
                                                  <small>{errors.expiry}</small>
                                                </div>
                                              ) : null}
                                            </div>
                                            <div className="cvv_wrap_inner">
                                              <div className="input_wrap form-floating">
                                                <InputMask
                                                  type="text"
                                                  className="form-control"
                                                  placeholder="123"
                                                  mask="9999"
                                                  maskChar=""
                                                  name="cvv"
                                                  max={4}
                                                  value={values.cvv}
                                                  onChange={handleCvvChange}
                                                  onBlur={handleBlur}
                                                />
                                                <label htmlFor="floatingInput">
                                                  Cvv
                                                </label>
                                              </div>
                                              {errors.cvv && touched.cvv ? (
                                                <div style={{ color: "red" }}>
                                                  <small>{errors.cvv}</small>
                                                </div>
                                              ) : null}
                                            </div>
                                          </div>
                                          <div className="input_wrap form-floating">
                                            <input
                                              type="text"
                                              className="form-control"
                                              id="floatingInput"
                                              placeholder="Enter your street address here"
                                              name="billAddress1"
                                              value={values.billAddress1}
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                            />
                                            <label htmlFor="floatingInput">
                                              Street line 1
                                            </label>
                                          </div>
                                          {errors.billAddress1 &&
                                          touched.billAddress1 ? (
                                            <div style={{ color: "red" }}>
                                              <small>
                                                {errors.billAddress1}
                                              </small>
                                            </div>
                                          ) : null}
                                          <div className="input_wrap form-floating">
                                            <input
                                              type="text"
                                              className="form-control"
                                              id="floatingInput"
                                              placeholder="Enter your street address here"
                                              name="billAddress2"
                                              value={values.billAddress2}
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                            />
                                            <label htmlFor="floatingInput">
                                              Street line 2
                                            </label>
                                          </div>
                                          {errors.billAddress2 &&
                                          touched.billAddress2 ? (
                                            <div style={{ color: "red" }}>
                                              <small>
                                                {errors.billAddress2}
                                              </small>
                                            </div>
                                          ) : null}
                                          <div className="input_wrap form-floating">
                                            <input
                                              type="city"
                                              className="form-control"
                                              id="floatingInput"
                                              placeholder="Enter your city"
                                              name="city"
                                              value={values.city}
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                            />
                                            <label htmlFor="floatingInput">
                                              City
                                            </label>
                                          </div>
                                          {errors.city && touched.city ? (
                                            <div style={{ color: "red" }}>
                                              <small>{errors.city}</small>
                                            </div>
                                          ) : null}
                                          <div className="input_wrap mb_16">
                                            <label
                                              style={{ color: "white" }}
                                              className="mb_16"
                                            >
                                              State/Province
                                            </label>
                                            <Select
                                              className="form-control"
                                              classNamePrefix="state"
                                              style={{ marginBottom: "8px" }}
                                              defaultValue={values.state}
                                              isSearchable={true}
                                              styles={customStyles}
                                              placeholder="Select state"
                                              name="state"
                                              onBlur={handleBlur}
                                              // value={values.state}
                                              onChange={(selectedOption) => {
                                                setFieldValue(
                                                  "state",
                                                  selectedOption?.value
                                                ); // Update 'state' field in Formik values
                                              }}
                                              options={whiteListedStates.map(
                                                (state) => ({
                                                  value: state.abbreviation,
                                                  label: state.region,
                                                })
                                              )}
                                            />
                                          </div>
                                          {errors.state && touched.state ? (
                                            <div style={{ color: "red" }}>
                                              <small>{errors.state}</small>
                                            </div>
                                          ) : null}
                                          <div
                                            style={{ marginTop: "8px" }}
                                            className="input_wrap form-floating"
                                          >
                                            <input
                                              type="number"
                                              className="form-control"
                                              id="floatingInput"
                                              placeholder="Enter your Zip code"
                                              name="zipCode"
                                              value={values.zipCode}
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                            />
                                            <label htmlFor="floatingInput">
                                              Zip
                                            </label>
                                          </div>
                                          {errors.zipCode && touched.zipCode ? (
                                            <div style={{ color: "red" }}>
                                              <small>{errors.zipCode}</small>
                                            </div>
                                          ) : null}
                                          <div className="input_wrap form-floating">
                                            <input
                                              type="text"
                                              className="form-control"
                                              id="floatingInput"
                                              placeholder="Enter your Zip code"
                                              // name="country"
                                              defaultValue={values.country}
                                              readOnly
                                            />
                                            {touched.country &&
                                              errors.country && (
                                                <span style={{ color: "red" }}>
                                                  {errors.country}
                                                </span>
                                              )}
                                            <label htmlFor="floatingInput">
                                              Country
                                            </label>
                                          </div>
                                          <div className="support_btn d-flex  justify-content-between">
                                            <button
                                              onClick={hanldeShowAddCardClose}
                                              className={`f24 themebtn-dark box-red cashout-btn ${"christmas-dark-btn"}`}
                                            >
                                              Cancel
                                            </button>
                                            <button
                                              disabled={loading ? true : false}
                                              className={`f24 themebtn-red box-red cashout-btn  ${"christmas-btn"}`}
                                              type="submit"
                                            >
                                              {loading ? (
                                                <SpinnerDotted
                                                  style={{
                                                    height: "40",
                                                    color: "white",
                                                  }}
                                                />
                                              ) : (
                                                "Save Card"
                                              )}
                                            </button>
                                          </div>
                                        </div>
                                      </form>
                                    </>
                                  )} */}
                              {statusKyc ? null : (
                                <>
                                  {!showAddCard && (
                                    <div className="fomBox cash-out-outer-btn">
                                      <button
                                        onClick={handleCashout}
                                        className={`f24 themebtn-blue cashout-btn ${
                                          selectedCard ? "" : "btn_opc_70"
                                        }`}
                                      >
                                        Continue to Payout
                                      </button>
                                    </div>
                                  )}
                                </>
                              )}
                            </div>
                          </>
                        </>
                      ) : (
                        <>
                          <div className="swich_cashout_btn">
                            {allowedPaymentMethods.IBFT.includes(
                              "approvely"
                            ) && (
                              <button
                                onClick={() =>
                                  handleSwitchACHPaymentMethod("approvely")
                                }
                                className={`${
                                  achCashoutMethod === "approvely"
                                    ? "active"
                                    : ""
                                }`}
                              >
                                Approvely
                              </button>
                            )}
                            {allowedPaymentMethods.IBFT.includes("nuvei") && (
                              <button
                                onClick={() =>
                                  handleSwitchACHPaymentMethod("nuvei")
                                }
                                className={`${
                                  achCashoutMethod === "nuvei" ? "active" : ""
                                }`}
                              >
                                Nuvei
                              </button>
                            )}
                          </div>
                          {achCashoutMethod === "nuvei" && (
                            <div className="credit-debit-wrap">
                              <h2 className="f20 ">
                                {!userAddedAchCards?.length > 0
                                  ? "Add a account to payout"
                                  : "Manage your linked accounts"}
                              </h2>
                              <p className="f14">
                                {!userAddedAchCards?.length > 0 ? (
                                  <>
                                    To proceed with your withdrawals, please
                                    link a valid account and provide additional
                                    required details, including your phone
                                    number. Click "Add New Account" to begin.
                                  </>
                                ) : (
                                  <>
                                    You've already added one or more accounts
                                    for cashing out. You can add additional
                                    accounts as needed. Remember to keep your
                                    details, including your phone number, up to
                                    date.
                                  </>
                                )}
                              </p>
                              {showAddCardAch === false && (
                                <h2 className="f20 mt-32 ">
                                  {!userAddedAchCards?.length > 0
                                    ? "New account"
                                    : "Saved accounts"}{" "}
                                </h2>
                              )}

                              {allowedPaymentMethods.IBFT.includes("nuvei") &&
                                !showAddCardAch &&
                                userAddedAchCards?.map((AchCard, index) => (
                                  <div
                                    key={index}
                                    onClick={() =>
                                      handleSelectedCard(
                                        AchCard?.userPaymentOptionId,
                                        AchCard
                                      )
                                    }
                                    className={`user_cards_wrap ${
                                      AchCard?.userPaymentOptionId ===
                                      selectedCard
                                        ? "active"
                                        : ""
                                    }`}
                                  >
                                    <div className="user_card ">
                                      <div className="card_img d-flex">
                                        <img
                                          src={
                                            // cardImages[AchCard?.card?.cardBrand] ||
                                            defaultCard
                                          }
                                          alt="card-img"
                                        />
                                      </div>
                                      <div className="card_details">
                                        <h1 className="f20  mb-0 fw-600">
                                          {AchCard?.upoName
                                            ? `ACH CARD ${AchCard?.upoName}`
                                            : AchCard?.upoData?.buyerName
                                            ? AchCard?.upoData?.buyerName
                                            : AchCard?.upoData
                                                ?.mazooma_fi_account_number}
                                        </h1>
                                        <p className="f14  mb-0">
                                          {AchCard?.mazooma_fi_routing
                                            ? `Routing - ${AchCard?.mazooma_fi_routing}`
                                            : AchCard?.upoData?.mazoomaFiName
                                            ? `Bank - ${AchCard?.upoData?.mazoomaFiName}`
                                            : `ACC Type - ${AchCard?.upoData?.mazooma_fi_account_type}`}
                                        </p>
                                      </div>
                                      <div className="delete_card d-flex justify-content-center">
                                        {cardLoadingAch[
                                          AchCard?.userPaymentOptionId
                                        ] ? (
                                          <>
                                            <SpinnerDotted
                                              style={{
                                                height: "24",
                                                color: "white",
                                              }}
                                            />
                                          </>
                                        ) : (
                                          <>
                                            <img
                                              onClick={(e) =>
                                                handleDeleteCardsAch(
                                                  e,
                                                  AchCard?.userPaymentOptionId
                                                )
                                              }
                                              src={DeleteImg}
                                              alt="del"
                                            />
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              {allowedPaymentMethods.IBFT.includes("nuvei") &&
                                !showAddCardAch && (
                                  <div className="cashout_btn_wrap ">
                                    <button
                                      onClick={hanldeShowAddCardAch}
                                      className="cashout_btn f24 mt-8"
                                    >
                                      Add new account
                                    </button>
                                  </div>
                                )}
                              {allowedPaymentMethods.IBFT.includes("nuvei") &&
                                showAddCardAch && (
                                  <>
                                    <form
                                      className="fomBox "
                                      onClick={handleUnsetCard}
                                      onSubmit={handleSubmitAch}
                                    >
                                      <div className="main-wrap-form">
                                        <div className="expire_wrapper_cashout">
                                          <div className="w49">
                                            <div className="input_wrap form-floating">
                                              <input
                                                type="text"
                                                className="form-control"
                                                id="floatingInput"
                                                placeholder="Enter your address"
                                                name="firstname"
                                                value={valuesAch.firstname}
                                                onChange={handleChangeAch}
                                                onBlur={handleBlurAch}
                                              />
                                              <label htmlFor="floatingInput">
                                                Firstname
                                              </label>
                                            </div>
                                            {errorsAch.firstname &&
                                            touchedAch.firstname ? (
                                              <div style={{ color: "red" }}>
                                                <small>
                                                  {errorsAch.firstname}
                                                </small>
                                              </div>
                                            ) : null}
                                          </div>
                                          <div className="w49">
                                            <div className="input_wrap form-floating">
                                              <input
                                                type="text"
                                                className="form-control"
                                                id="floatingInput"
                                                placeholder="Enter your address"
                                                name="lastname"
                                                value={valuesAch.lastname}
                                                onChange={handleChangeAch}
                                                onBlur={handleBlurAch}
                                              />
                                              <label htmlFor="floatingInput">
                                                Lastname
                                              </label>
                                            </div>
                                            {errorsAch.lastname &&
                                            touchedAch.lastname ? (
                                              <div style={{ color: "red" }}>
                                                <small>
                                                  {errorsAch.lastname}
                                                </small>
                                              </div>
                                            ) : null}
                                          </div>
                                        </div>

                                        <div className="input_wrap form-floating">
                                          <input
                                            type="text"
                                            className="form-control"
                                            id="floatingInput"
                                            placeholder="Enter your address"
                                            name="billAddress1"
                                            value={valuesAch.billAddress1}
                                            onChange={handleChangeAch}
                                            onBlur={handleBlurAch}
                                          />
                                          <label htmlFor="floatingInput">
                                            Address line 1
                                          </label>
                                        </div>
                                        {errorsAch.billAddress1 &&
                                        touchedAch.billAddress1 ? (
                                          <div style={{ color: "red" }}>
                                            <small>
                                              {errorsAch.billAddress1}
                                            </small>
                                          </div>
                                        ) : null}
                                        <div className="input_wrap form-floating">
                                          <input
                                            type="text"
                                            className="form-control"
                                            id="floatingInput"
                                            placeholder="Enter your address"
                                            name="phone_number"
                                            value={valuesAch.phone_number}
                                            onChange={handleChangeAch}
                                            onBlur={handleBlurAch}
                                          />
                                          <label htmlFor="floatingInput">
                                            Phone number
                                          </label>
                                        </div>
                                        {errorsAch.phone_number &&
                                        touchedAch.phone_number ? (
                                          <div style={{ color: "red" }}>
                                            <small>
                                              {errorsAch.phone_number}
                                            </small>
                                          </div>
                                        ) : null}

                                        <div className="expire_wrapper_cashout">
                                          <div className="w49">
                                            <div className="input_wrap form-floating">
                                              <input
                                                type="text"
                                                className="form-control"
                                                id="floatingInput"
                                                placeholder="Enter your address"
                                                name="city"
                                                value={valuesAch.city}
                                                onChange={handleChangeAch}
                                                onBlur={handleBlurAch}
                                              />
                                              <label htmlFor="floatingInput">
                                                City
                                              </label>
                                            </div>
                                            {errorsAch.city &&
                                            touchedAch.city ? (
                                              <div style={{ color: "red" }}>
                                                <small>{errorsAch.city}</small>
                                              </div>
                                            ) : null}
                                          </div>
                                          <div className="w49">
                                            <div className="input_wrap ">
                                              <Select
                                                style={{ marginTop: "3px" }}
                                                className="form-control"
                                                classNamePrefix="state"
                                                defaultValue={valuesAch.state}
                                                isSearchable={true}
                                                styles={customStyles}
                                                placeholder="Select state"
                                                name="state"
                                                onBlur={handleBlurAch}
                                                // value={values.state}
                                                onChange={(selectedOption) => {
                                                  setFieldValueAch(
                                                    "state",
                                                    selectedOption?.value
                                                  ); // Update 'state' field in Formik values
                                                }}
                                                options={whiteListedStates.map(
                                                  (state) => ({
                                                    value: state.abbreviation,
                                                    label: state.region,
                                                  })
                                                )}
                                              />
                                            </div>
                                            {errorsAch.state &&
                                            touchedAch.state ? (
                                              <div style={{ color: "red" }}>
                                                <small>{errorsAch.state}</small>
                                              </div>
                                            ) : null}
                                          </div>
                                        </div>
                                        <div className="expire_wrapper_cashout">
                                          <div className="w49">
                                            <div className="input_wrap form-floating">
                                              <input
                                                type="text"
                                                className="form-control"
                                                id="floatingInput"
                                                placeholder="Enter your address"
                                                name="zipCode"
                                                value={valuesAch.zipCode}
                                                onChange={handleChangeAch}
                                                onBlur={handleBlurAch}
                                              />
                                              <label htmlFor="floatingInput">
                                                Zipcode
                                              </label>
                                            </div>
                                            {errorsAch.zipCode &&
                                            touchedAch.zipCode ? (
                                              <div style={{ color: "red" }}>
                                                <small>
                                                  {errorsAch.zipCode}
                                                </small>
                                              </div>
                                            ) : null}
                                          </div>
                                          <div className="w49">
                                            <div className="input_wrap form-floating">
                                              <input
                                                type="text"
                                                className="form-control"
                                                id="floatingInput"
                                                placeholder="Enter your address"
                                                name="country"
                                                defaultValue={valuesAch.country}
                                                readOnly
                                              />
                                              <label htmlFor="floatingInput">
                                                Country
                                              </label>
                                            </div>
                                            {errorsAch.country &&
                                            touchedAch.country ? (
                                              <div style={{ color: "red" }}>
                                                <small>
                                                  {errorsAch.country}
                                                </small>
                                              </div>
                                            ) : null}
                                          </div>
                                        </div>
                                        <div className="support_btn d-flex  justify-content-between">
                                          <button
                                            type="button"
                                            onClick={hanldeShowAddCardCloseAch}
                                            className="f24 themebtn-dark box-red cashout-btn"
                                          >
                                            Cancel
                                          </button>
                                          <button
                                            disabled={loadingAch ? true : false}
                                            className="f24 themebtn-blue box-red cashout-btn "
                                            type="submit"
                                          >
                                            {loadingAch ? (
                                              <SpinnerDotted
                                                style={{
                                                  height: "40",
                                                  color: "white",
                                                }}
                                              />
                                            ) : (
                                              "Save Account"
                                            )}
                                          </button>
                                        </div>
                                      </div>
                                    </form>
                                  </>
                                )}
                              {handleSwitchCashoutMethod === "credit/debit" ? (
                                <>
                                  {!showAddCard && (
                                    <div className="fomBox cash-out-outer-btn">
                                      <button
                                        onClick={handleCashout}
                                        className={`f24 themebtn-blue cashout-btn ${
                                          selectedCard ? "" : "btn_opc_70"
                                        }`}
                                      >
                                        Continue to Payout
                                      </button>
                                    </div>
                                  )}
                                </>
                              ) : (
                                <>
                                  {!showAddCardAch && (
                                    <div className="fomBox cash-out-outer-btn">
                                      <button
                                        onClick={handleCashout}
                                        className={`f24 themebtn-blue cashout-btn ${
                                          selectedCard ? "" : "btn_opc_70"
                                        }`}
                                      >
                                        Continue to Payout
                                      </button>
                                    </div>
                                  )}
                                </>
                              )}
                            </div>
                          )}
                          {achCashoutMethod === "approvely" && (
                            <div className="credit-debit-wrap">
                              <h2 className="f20 ">
                                {!userApprovelySavedACH?.length > 0
                                  ? "Add a account to payout"
                                  : "Manage your linked accounts"}
                              </h2>
                              <p className="f14">
                                {!userApprovelySavedACH?.length > 0 ? (
                                  <>
                                    To proceed with your withdrawals, please
                                    link a valid account and provide additional
                                    required details, including your phone
                                    number. Click "Add New Account" to begin.
                                  </>
                                ) : (
                                  <>
                                    You've already added one or more accounts
                                    for cashing out. You can add additional
                                    accounts as needed. Remember to keep your
                                    details, including your phone number, up to
                                    date.
                                  </>
                                )}
                              </p>
                              {showAddCardAch === false && (
                                <h2 className="f20 mt-32 ">
                                  {!userApprovelySavedACH?.length > 0
                                    ? "New account"
                                    : "Saved accounts"}{" "}
                                </h2>
                              )}

                              {allowedPaymentMethods.IBFT.includes(
                                "approvely"
                              ) &&
                                !showAddCardAch &&
                                userApprovelySavedACH?.map((AchCard, index) => (
                                  <div
                                    key={index}
                                    onClick={() =>
                                      handleSelectedCard(
                                        AchCard?.token,
                                        AchCard
                                      )
                                    }
                                    className={`user_cards_wrap  ${
                                      AchCard?.token === selectedCard
                                        ? "active"
                                        : ""
                                    }`}
                                  >
                                    <div className="user_card ">
                                      <div className="card_img d-flex">
                                        <img
                                          src={
                                            // cardImages[AchCard?.card?.cardBrand] ||
                                            defaultCard
                                          }
                                          alt="card-img"
                                        />
                                      </div>
                                      <div className="card_details">
                                        <h1 className="f20  mb-0 fw-600">
                                          {AchCard?.alias}
                                        </h1>
                                      </div>
                                      <div className="delete_card d-flex justify-content-center">
                                        {cardLoadingAch[AchCard?.token] ? (
                                          <>
                                            <SpinnerDotted
                                              style={{
                                                height: "24",
                                                color: "white",
                                              }}
                                            />
                                          </>
                                        ) : (
                                          <>
                                            <img
                                              onClick={(e) =>
                                                handleDeleteAccountACHApprovely(
                                                  e,
                                                  AchCard?.token
                                                )
                                              }
                                              src={DeleteImg}
                                              alt="del"
                                            />
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              {allowedPaymentMethods.IBFT.includes(
                                "approvely"
                              ) &&
                                !showAddCardAch && (
                                  <div className="cashout_btn_wrap ">
                                    {statusKycMethod ? (
                                      <FormKycApprovely
                                        setStatusKycMethod={setStatusKycMethod}
                                        setStatusKyc={setStatusKyc}
                                      />
                                    ) : (
                                      <AddAccountACHApprovely />
                                    )}
                                  </div>
                                )}
                              {allowedPaymentMethods.IBFT.includes("nuvei") &&
                                showAddCardAch && (
                                  <>
                                    <form
                                      className="fomBox "
                                      onClick={handleUnsetCard}
                                      onSubmit={handleSubmitAch}
                                    >
                                      <div className="main-wrap-form">
                                        <div className="expire_wrapper_cashout">
                                          <div className="w49">
                                            <div className="input_wrap form-floating">
                                              <input
                                                type="text"
                                                className="form-control"
                                                id="floatingInput"
                                                placeholder="Enter your address"
                                                name="firstname"
                                                value={valuesAch.firstname}
                                                onChange={handleChangeAch}
                                                onBlur={handleBlurAch}
                                              />
                                              <label htmlFor="floatingInput">
                                                Firstname
                                              </label>
                                            </div>
                                            {errorsAch.firstname &&
                                            touchedAch.firstname ? (
                                              <div style={{ color: "red" }}>
                                                <small>
                                                  {errorsAch.firstname}
                                                </small>
                                              </div>
                                            ) : null}
                                          </div>
                                          <div className="w49">
                                            <div className="input_wrap form-floating">
                                              <input
                                                type="text"
                                                className="form-control"
                                                id="floatingInput"
                                                placeholder="Enter your address"
                                                name="lastname"
                                                value={valuesAch.lastname}
                                                onChange={handleChangeAch}
                                                onBlur={handleBlurAch}
                                              />
                                              <label htmlFor="floatingInput">
                                                Lastname
                                              </label>
                                            </div>
                                            {errorsAch.lastname &&
                                            touchedAch.lastname ? (
                                              <div style={{ color: "red" }}>
                                                <small>
                                                  {errorsAch.lastname}
                                                </small>
                                              </div>
                                            ) : null}
                                          </div>
                                        </div>

                                        <div className="input_wrap form-floating">
                                          <input
                                            type="text"
                                            className="form-control"
                                            id="floatingInput"
                                            placeholder="Enter your address"
                                            name="billAddress1"
                                            value={valuesAch.billAddress1}
                                            onChange={handleChangeAch}
                                            onBlur={handleBlurAch}
                                          />
                                          <label htmlFor="floatingInput">
                                            Address line 1
                                          </label>
                                        </div>
                                        {errorsAch.billAddress1 &&
                                        touchedAch.billAddress1 ? (
                                          <div style={{ color: "red" }}>
                                            <small>
                                              {errorsAch.billAddress1}
                                            </small>
                                          </div>
                                        ) : null}
                                        <div className="input_wrap form-floating">
                                          <input
                                            type="text"
                                            className="form-control"
                                            id="floatingInput"
                                            placeholder="Enter your address"
                                            name="phone_number"
                                            value={valuesAch.phone_number}
                                            onChange={handleChangeAch}
                                            onBlur={handleBlurAch}
                                          />
                                          <label htmlFor="floatingInput">
                                            Phone number
                                          </label>
                                        </div>
                                        {errorsAch.phone_number &&
                                        touchedAch.phone_number ? (
                                          <div style={{ color: "red" }}>
                                            <small>
                                              {errorsAch.phone_number}
                                            </small>
                                          </div>
                                        ) : null}

                                        <div className="expire_wrapper_cashout">
                                          <div className="w49">
                                            <div className="input_wrap form-floating">
                                              <input
                                                type="text"
                                                className="form-control"
                                                id="floatingInput"
                                                placeholder="Enter your address"
                                                name="city"
                                                value={valuesAch.city}
                                                onChange={handleChangeAch}
                                                onBlur={handleBlurAch}
                                              />
                                              <label htmlFor="floatingInput">
                                                City
                                              </label>
                                            </div>
                                            {errorsAch.city &&
                                            touchedAch.city ? (
                                              <div style={{ color: "red" }}>
                                                <small>{errorsAch.city}</small>
                                              </div>
                                            ) : null}
                                          </div>
                                          <div className="w49">
                                            <div className="input_wrap ">
                                              <Select
                                                style={{ marginTop: "3px" }}
                                                className="form-control"
                                                classNamePrefix="state"
                                                defaultValue={valuesAch.state}
                                                isSearchable={true}
                                                styles={customStyles}
                                                placeholder="Select state"
                                                name="state"
                                                onBlur={handleBlurAch}
                                                // value={values.state}
                                                onChange={(selectedOption) => {
                                                  setFieldValueAch(
                                                    "state",
                                                    selectedOption?.value
                                                  ); // Update 'state' field in Formik values
                                                }}
                                                options={whiteListedStates.map(
                                                  (state) => ({
                                                    value: state.abbreviation,
                                                    label: state.region,
                                                  })
                                                )}
                                              />
                                            </div>
                                            {errorsAch.state &&
                                            touchedAch.state ? (
                                              <div style={{ color: "red" }}>
                                                <small>{errorsAch.state}</small>
                                              </div>
                                            ) : null}
                                          </div>
                                        </div>
                                        <div className="expire_wrapper_cashout">
                                          <div className="w49">
                                            <div className="input_wrap form-floating">
                                              <input
                                                type="text"
                                                className="form-control"
                                                id="floatingInput"
                                                placeholder="Enter your address"
                                                name="zipCode"
                                                value={valuesAch.zipCode}
                                                onChange={handleChangeAch}
                                                onBlur={handleBlurAch}
                                              />
                                              <label htmlFor="floatingInput">
                                                Zipcode
                                              </label>
                                            </div>
                                            {errorsAch.zipCode &&
                                            touchedAch.zipCode ? (
                                              <div style={{ color: "red" }}>
                                                <small>
                                                  {errorsAch.zipCode}
                                                </small>
                                              </div>
                                            ) : null}
                                          </div>
                                          <div className="w49">
                                            <div className="input_wrap form-floating">
                                              <input
                                                type="text"
                                                className="form-control"
                                                id="floatingInput"
                                                placeholder="Enter your address"
                                                name="country"
                                                defaultValue={valuesAch.country}
                                                readOnly
                                              />
                                              <label htmlFor="floatingInput">
                                                Country
                                              </label>
                                            </div>
                                            {errorsAch.country &&
                                            touchedAch.country ? (
                                              <div style={{ color: "red" }}>
                                                <small>
                                                  {errorsAch.country}
                                                </small>
                                              </div>
                                            ) : null}
                                          </div>
                                        </div>
                                        <div className="support_btn d-flex  justify-content-between">
                                          <button
                                            type="button"
                                            onClick={hanldeShowAddCardCloseAch}
                                            className="f24 themebtn-dark box-red cashout-btn"
                                          >
                                            Cancel
                                          </button>
                                          <button
                                            disabled={loadingAch ? true : false}
                                            className="f24 themebtn-blue box-red cashout-btn "
                                            type="submit"
                                          >
                                            {loadingAch ? (
                                              <SpinnerDotted
                                                style={{
                                                  height: "40",
                                                  color: "white",
                                                }}
                                              />
                                            ) : (
                                              "Save Account"
                                            )}
                                          </button>
                                        </div>
                                      </div>
                                    </form>
                                  </>
                                )}
                              {handleSwitchCashoutMethod === "credit/debit" ? (
                                <>
                                  {!showAddCard && (
                                    <div className="fomBox cash-out-outer-btn">
                                      <button
                                        onClick={handleCashout}
                                        className={`f24 themebtn-blue cashout-btn ${
                                          selectedCard ? "" : "btn_opc_70"
                                        }`}
                                      >
                                        Continue to Payout
                                      </button>
                                    </div>
                                  )}
                                </>
                              ) : (
                                <>
                                  {!showAddCardAch && (
                                    <div className="fomBox cash-out-outer-btn">
                                      <button
                                        onClick={handleCashout}
                                        className={`f24 themebtn-blue cashout-btn ${
                                          selectedCard ? "" : "btn_opc_70"
                                        }`}
                                      >
                                        Continue to Payout
                                      </button>
                                    </div>
                                  )}
                                </>
                              )}
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <form className="fomBox" onSubmit={handleSubmit2}>
                      <div className="main-wrap-form">
                        <div className="confirm-cash-wrapp">
                          <h2 className="f20 cash-bln">
                            Payout from your balance
                          </h2>
                          <div className="input_wrap form-floating">
                            <input
                              type="number"
                              className="form-control"
                              id="floatingInput"
                              placeholder="Enter amount"
                              onChange={handleChange2("amount")}
                              onBlur={handleBlur2("amount")}
                              value={values2.amount}
                            />
                            <label htmlFor="floatingInput">
                              Withdraw Amount in USD
                            </label>
                            {errors2.amount && touched2.amount ? (
                              <div style={{ color: "red", textAlign: "end" }}>
                                <small>{errors2.amount}</small>
                              </div>
                            ) : null}
                          </div>

                          <div className="cashout_withdraw_btn_wrap mt-32 mb-32">
                            <button
                              disabled={loadingWithdraw ? true : false}
                              className="f24 themebtn-blue box-red cashout-btn "
                              type="submit"
                            >
                              {loadingWithdraw ? (
                                <SpinnerDotted
                                  style={{ height: "40", color: "white" }}
                                />
                              ) : (
                                "Confirm Cash-Out"
                              )}
                            </button>
                            {success && (
                              <div className="err-mess-wrp  mt-8">
                                <p className="">
                                  Payout submitted! Await processing
                                </p>
                              </div>
                            )}
                            {fail && (
                              <div className="err-mess-wrp err-mess-wrp-red  mt-8">
                                <p className="">
                                  Error! Check details and retry
                                </p>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </form>
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Billing;
